import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  CreateUserInput,
  CreateUserMutation,
  DeleteUserInput,
  DeleteUserMutation,
  GetUserQuery,
  GetUserQueryVariables,
  ListUsersBySubQuery,
  ListUsersBySubQueryVariables,
  ListUsersQuery,
  ListUsersQueryVariables,
  UpdateUserInput,
  UpdateUserMutation,
  User,
} from "../API";
import { getUser, listUsers, listUsersBySub } from "../graphql/queries";
import { createUser, deleteUser, updateUser } from "../graphql/mutations";
import addBaseFilterToVariables from "./helper/filter-deleted";

export async function queryItem(variables: GetUserQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<GetUserQuery>>(
    graphqlOperation(getUser, variables ? variables : {})
  );
  return payload.data?.getUser as User;
}
export async function queryList(variables: ListUsersQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<ListUsersQuery>>(
    graphqlOperation(
      listUsers,
      variables ? addBaseFilterToVariables(variables) : {}
    )
  );
  return payload.data?.listUsers?.items as User[];
}
export async function queryListBySubID(
  variables: ListUsersBySubQueryVariables
) {
  const payload = await API.graphql<GraphQLQuery<ListUsersBySubQuery>>(
    graphqlOperation(
      listUsersBySub,
      variables ? addBaseFilterToVariables(variables) : {}
    )
  );
  return payload.data?.listUsersBySub?.items as User[];
}
export async function createItem(input: CreateUserInput) {
  const payload = await API.graphql<GraphQLQuery<CreateUserMutation>>({
    query: createUser,
    variables: { input },
  });
  return payload.data?.createUser;
}
export async function updateItem(input: UpdateUserInput) {
  const payload = await API.graphql<GraphQLQuery<UpdateUserMutation>>({
    query: updateUser,
    variables: { input },
  });
  return payload.data?.updateUser as User;
}
export async function deleteItem(input: DeleteUserInput) {
  await API.graphql<GraphQLQuery<DeleteUserMutation>>({
    query: deleteUser,
    variables: { input },
  });
}

import { API, graphqlOperation } from "aws-amplify";
import {
  GraphQLResult,
  GraphQLQuery,
  GraphQLSubscription,
} from "@aws-amplify/api";

import {
  GetOrderQuery,
  GetOrderQueryVariables,
  ListOrdersQuery,
  ListOrdersQueryVariables,
  OnUpdateOrderSubscription,
  OnUpdateOrderSubscriptionVariables,
  Order,
  UpdateOrderInput,
  UpdateOrderMutation,
} from "../API";

import { getOrder, listOrders } from "../graphql/queries";
import { onUpdateOrder } from "../graphql/subscriptions";
import { updateOrder } from "../graphql/mutations";
import addBaseFilterToVariables from "./helper/filter-deleted";

export async function fetchOrderByID(variables: GetOrderQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<GetOrderQuery>>(
    graphqlOperation(getOrder, variables ? variables : {})
  );

  return payload.data?.getOrder as Order;
}

export async function fetchOrders(v: ListOrdersQueryVariables) {
  let listOrderItems: Order[] = [];
  let listOrderDishesResponse: GraphQLResult<GraphQLQuery<ListOrdersQuery>> =
    {};
  let variables = addBaseFilterToVariables(v);

  listOrderDishesResponse = await API.graphql<GraphQLQuery<ListOrdersQuery>>(
    graphqlOperation(listOrders, variables ? variables : {})
  );

  listOrderItems = [
    ...(listOrderItems || []),
    ...(listOrderDishesResponse?.data?.listOrders?.items || []),
  ] as Order[];

  while (listOrderDishesResponse?.data?.listOrders?.nextToken) {
    console.log(`➿[QUERY LIST ORDER LOOPING START]`);

    variables.nextToken = listOrderDishesResponse.data?.listOrders?.nextToken;

    listOrderDishesResponse = await API.graphql<GraphQLQuery<ListOrdersQuery>>({
      query: listOrders,
      variables: variables,
    });

    listOrderItems = [
      ...listOrderItems,
      ...(listOrderDishesResponse?.data?.listOrders?.items || []),
    ] as Order[];

    console.log(
      `🍀[QUERY LIST ORDER LOOPING LOOPING SUCCESS]`,
      listOrderDishesResponse?.data?.listOrders?.items.length
    );
  }

  return listOrderItems as Order[];
}

export async function updateOrderByID(input: UpdateOrderInput) {
  const origin = await fetchOrderByID({ id: input.id });
  const inputWithVersion = {
    ...input,
    _version: origin?._version,
  };

  const payload = await API.graphql<GraphQLQuery<UpdateOrderMutation>>({
    query: updateOrder,
    variables: { input: inputWithVersion },
  });
  return payload.data?.updateOrder as Order;
}

// Subscribe to update of orders
export const observeOrders = (variables: OnUpdateOrderSubscriptionVariables) =>
  API.graphql<GraphQLSubscription<OnUpdateOrderSubscription>>({
    query: onUpdateOrder,
    variables,
  });

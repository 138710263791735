import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";

import {
  CreateDishInput,
  CreateDishMutation,
  DeleteDishInput,
  DeleteDishMutation,
  Dish,
  GetDishQuery,
  GetDishQueryVariables,
  ListDishesQuery,
  ListDishesQueryVariables,
  OnUpdateDishSubscription,
  OnUpdateDishSubscriptionVariables,
  UpdateDishInput,
  UpdateDishMutation,
} from "../API";

import { createDish, deleteDish, updateDish } from "../graphql/mutations";
import { getDish, listDishes } from "../graphql/queries";
import { onUpdateDish } from "../graphql/subscriptions";
import addBaseFilterToVariables from "./helper/filter-deleted";
import removeTypename from "./helper/remove-typename";

export async function fetchDishByID(variables: GetDishQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<GetDishQuery>>(
    graphqlOperation(getDish, variables ? variables : {})
  );

  return payload.data?.getDish as Dish;
}

export async function queryList(variables: ListDishesQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<ListDishesQuery>>(
    graphqlOperation(
      listDishes,
      variables ? addBaseFilterToVariables(variables) : {}
    )
  );
  return payload.data?.listDishes?.items as Dish[];
}

export async function createItem(input: CreateDishInput) {
  const payload = await API.graphql<GraphQLQuery<CreateDishMutation>>({
    query: createDish,
    variables: { input: removeTypename(input) },
  });
  return payload.data?.createDish as Dish;
}

export async function updateDishByID(input: UpdateDishInput) {
  const origin = await fetchDishByID({ id: input.id });
  const inputWithVersion = {
    ...input,
    _version: origin?._version,
  };

  const payload = await API.graphql<GraphQLQuery<UpdateDishMutation>>({
    query: updateDish,
    variables: { input: removeTypename(inputWithVersion) },
  });
  return payload.data?.updateDish as Dish;
}

export async function deleteItem(input: DeleteDishInput) {
  await API.graphql<GraphQLQuery<DeleteDishMutation>>({
    query: deleteDish,
    variables: { input: removeTypename(input) },
  });
}

// Subscribe to update of dishes
export const observeDishes = (variables: OnUpdateDishSubscriptionVariables) =>
  API.graphql<GraphQLSubscription<OnUpdateDishSubscription>>({
    query: onUpdateDish,
    variables,
  });

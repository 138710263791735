import * as React from "react";
import { AdminPanelSettingsTwoTone } from "@mui/icons-material";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Modal from "@mui/material/Modal";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Protected({
  children,
}: {
  children: React.ReactElement | null;
}) {
  const nav = useNavigate();

  const [error, setError] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  React.useEffect(() => {
    const json = localStorage.getItem("@ADMIN");

    if (!json) {
      // we are not authenticated as admin
      handleOpen();
      return;
    }

    const data = JSON.parse(json);

    const authValid = (date: moment.MomentInput) =>
      moment(date).isAfter(moment().subtract(30, "minutes"));

    if (!authValid(data.timestamp)) {
      // clear state
      localStorage.removeItem("@ADMIN");
      handleOpen();
    }
  }, [open]);

  const onClickLogin = () => {
    if (username === "Admin" && password === "TastyAdmin23!?") {
      handleClose();

      const data = {
        authenticated: true,
        timestamp: moment.now(),
      };

      localStorage.setItem("@ADMIN", JSON.stringify(data));
    } else {
      setError("Zugangsdaten inkorrekt");
    }
  };

  return (
    <div>
      {children}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {
          handleClose();
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            onClick: () => {},
          },
        }}
      >
        <Fade in={open}>
          <Box sx={[style]}>
            <Box flexDirection={"row"} display="flex" alignItems={"center"}>
              <AdminPanelSettingsTwoTone
                style={{ marginRight: 4 }}
                color="warning"
                fontSize="large"
              />
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Admin Bereich
              </Typography>
            </Box>
            <FormHelperText id="my-helper-text">
              Du befindest dich im Adminbereich. Sobald du dich eingeloggt hast
              wirst du 30 minuten Zeit haben Änderungen vorzunehmen.
            </FormHelperText>
            <FormControl margin={"normal"} fullWidth>
              <TextField
                style={{ marginBottom: 8 }}
                label="Admin"
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                label="Passwort"
                type="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>

            {error && <Typography color={"darkred"}>{error}</Typography>}

            <Box display="flex" flexDirection={"column"} marginTop={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onClickLogin}
              >
                Login
              </Button>
              <Button fullWidth color="error" onClick={() => nav("/")}>
                Abbrechen
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import { Delete, Remove } from "@mui/icons-material";
import { TimePicker } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import moment from "moment";
import _ from "lodash";

import { BusinessHourRestaurant, BusinessHourPeriod } from "../../API";
import { BusinessHoursRestaurantAPI } from "../../api-client";
import { useRestaurantContext } from "../../context/restaurant-context";

dayjs.extend(customParseFormat);

export default function BusinessHoursScreen() {
  const { restaurant } = useRestaurantContext();
  const [restaurantBusinessHours, setRestaurantBusinessHours] = useState<
    BusinessHourRestaurant[]
  >([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [label, setLabel] = useState<string>("");

  const handleClose = () => {
    setModalOpen(false);
    setDateRange([]);
    setLabel("");
    fetchBusinessHours();
  };
  const handleOpen = () => setModalOpen(true);

  const createNewBusinessHour = async (checkedArray: number[]) => {
    if (!restaurant?.id) {
      return alert("Keine Restaurant ID gefunden");
    }

    for await (const day of checkedArray) {
      const foundBusinessHour = restaurantBusinessHours.find(
        (b) => b.day === day
      );

      if (foundBusinessHour) {
        const original = await BusinessHoursRestaurantAPI.queryList({
          filter: {
            and: [
              { restaurantID: { eq: restaurant.id } },
              { day: { eq: day } },
            ],
          },
        });

        await BusinessHoursRestaurantAPI.updateItem({
          id: original[0].id,
          periods: [
            ...(original[0].periods || []),
            {
              start: dateRange[0],
              end: dateRange[1],
            },
          ],
        });
      } else {
        await BusinessHoursRestaurantAPI.createItem({
          restaurantID: restaurant?.id,
          day: day,
          periods: [{ start: dateRange[0], end: dateRange[1] }],
        });
      }
    }

    handleClose();
  };

  const deleteBusinessHour = async (businessHour: BusinessHourRestaurant) => {
    await BusinessHoursRestaurantAPI.deleteItem({
      id: businessHour.id,
      _version: businessHour._version,
    });
    fetchBusinessHours();
  };

  const deleteBusinessHourPeriod = async (
    businessHour: BusinessHourRestaurant,
    period: BusinessHourPeriod
  ) => {
    const reducedPeriods = businessHour.periods?.filter(
      (b) =>
        (b?.start! + b?.end!).toString() !==
        (period.start! + period.end!).toString()
    );

    if (reducedPeriods) {
      await BusinessHoursRestaurantAPI.updateItem({
        periods: reducedPeriods,
        id: businessHour.id,
      });

      fetchBusinessHours();
    }
  };

  const fetchBusinessHours = async () => {
    if (!restaurant?.id) {
      return;
    }

    BusinessHoursRestaurantAPI.queryList({
      filter: { restaurantID: { eq: restaurant.id } },
    }).then((data) => {
      setRestaurantBusinessHours(data);
    });
  };

  useEffect(() => {
    fetchBusinessHours();
  }, [restaurant?.id]);

  return (
    <Box>
      <Box flexDirection={"row"} display="flex" alignItems={"center"}>
        <Button
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          variant="outlined"
          color="secondary"
          onClick={handleOpen}
        >
          Schicht hinzufügen
        </Button>
      </Box>

      <List
        dense={false}
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      >
        {restaurantBusinessHours
          .sort((a, b) => a.day! - b.day!)
          .map((bh) => {
            return (
              <ListItem
                key={bh.id}
                alignItems="center"
                divider
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete-business-hour"
                    onClick={() => deleteBusinessHour(bh)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText
                  style={{ width: 50 }}
                  secondary={moment.weekdays(bh.day as number)}
                />
                <ListItemText>
                  {bh.periods?.map((period, index) => {
                    return (
                      <Box
                        key={index}
                        flexDirection={"row"}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <ListItemText key={index}>
                          {period?.start} Uhr - {period?.end} Uhr
                        </ListItemText>

                        <IconButton
                          size="small"
                          color={"warning"}
                          onClick={() => deleteBusinessHourPeriod(bh, period!)}
                        >
                          <Remove />
                        </IconButton>
                      </Box>
                    );
                  })}
                </ListItemText>
              </ListItem>
            );
          })}
      </List>

      <Modal open={modalOpen} onClose={handleClose}>
        <Card sx={modalStyle}>
          <Box padding={1}>
            <Typography fontWeight={"bold"}>
              {"Neue Schicht anlegen"}
            </Typography>
          </Box>
          {/*  <Box padding={1}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Beschreibung (optional)"
              variant="outlined"
              value={label}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLabel(event.target.value);
              }}
            />
          </Box> */}
          <Box padding={1}>
            <TimePicker.RangePicker
              allowClear
              size="large"
              format={"HH:mm"}
              /* value={[dayjs(parsedOpeningHours?.time?.[0]), dayjs(parsedOpeningHours?.time?.[1])]} */
              placeholder={["Start", "Ende"]}
              onCalendarChange={(v, stringDates, info) => {
                if (stringDates[0].length > 0 && stringDates[1].length > 0) {
                  return setDateRange(stringDates);
                }
                console.info("START and END musst be defined");
              }}
            />
          </Box>

          <Box padding={1}>
            <ControlledWeekdaySelection
              onPressSave={createNewBusinessHour}
              enableDaySelection={dateRange.length > 0}
            />
          </Box>
        </Card>
      </Modal>
    </Box>
  );
}

function ControlledWeekdaySelection({
  onPressSave,
  enableDaySelection,
}: {
  onPressSave: (checkedArray: number[]) => void;
  enableDaySelection: boolean;
}) {
  const [checkedArray, setChecked] = useState<number[]>([]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setChecked((prev) => [...prev, parseInt(event.target.value)]);
    } else {
      setChecked((prev) =>
        prev.filter((c) => c !== parseInt(event.target.value))
      );
    }
  };

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">
        Wochentage auswählen
      </FormLabel>
      <FormGroup>
        {moment.weekdays().map((weekday, index) => {
          return (
            <FormControlLabel
              key={index}
              value={index}
              disabled={!enableDaySelection}
              control={
                <Checkbox
                  size="small"
                  checked={checkedArray.includes(index)}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={weekday}
            />
          );
        })}
      </FormGroup>
      <Button
        style={{ marginTop: 16 }}
        variant="outlined"
        onClick={() => onPressSave(checkedArray)}
      >
        Speichern
      </Button>
    </FormControl>
  );
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  bgcolor: "background.paper",
  //border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

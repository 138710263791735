import { Link as RouterLink, useLocation } from "react-router-dom";

import Link, { LinkProps } from "@mui/material/Link";
import { ListItemProps } from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";

interface ListItemLinkProps extends ListItemProps {
  to: string;
  open?: boolean;
}

const breadcrumbNameMap: { [key: string]: string } = {
  "/": "Bestellungen",
  "/order": "Bestellung",
  "/order-history": "Historie",
  "/invoices": "Rechnungen",
  "/menu": "Speisekarte",
  "/menu/create": "Speisekarte erstellen",
  "/menu/edit": "Speisekarte aktualisieren",
  "/settings": "Einstellungen",
  "/business-hours": "Öffnungszeiten",
};

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

function containsNumbers(str: string) {
  return /\d/.test(str);
}

export default function Breadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname
    .split("/")
    .filter((x) => x && !containsNumbers(x));

  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      <LinkRouter underline="hover" color="inherit" to="/">
        {breadcrumbNameMap["/"]}
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <Typography color="text.primary" key={to}>
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {breadcrumbNameMap[to]}
          </LinkRouter>
        );
      })}
    </MUIBreadcrumbs>
  );
}

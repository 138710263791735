import { Divider, Rate, Typography } from "antd";

import { useRestaurantContext } from "../../context/restaurant-context";

export default function RestaurantInfoBox() {
  const { restaurant, authUser } = useRestaurantContext();

  return (
    <div style={{ display: "flex", flexDirection: "column", padding: 0 }}>
      <Divider />

      <div style={{ display: "flex", flexDirection: "column", padding: 16 }}>
        <Typography.Text strong>{"Restaurant-Info"}</Typography.Text>
        <Typography.Text>{restaurant?.name}</Typography.Text>
        <Typography.Text>{restaurant?.address}</Typography.Text>
        <Typography.Text
          copyable
          style={{ whiteSpace: "nowrap" }}
          ellipsis={true}
        >
          {restaurant?.id}
        </Typography.Text>
        <Rate allowHalf disabled defaultValue={restaurant?.rating || 0} />
      </div>

      <Divider />

      <div style={{ display: "flex", flexDirection: "column", padding: 16 }}>
        <Typography.Text strong>{"User-Info"}</Typography.Text>
        <Typography.Text>{authUser?.attributes?.email}</Typography.Text>

        <Typography.Text
          copyable
          style={{ whiteSpace: "nowrap" }}
          ellipsis={true}
        >
          ID: {authUser?.attributes?.sub}
        </Typography.Text>
      </div>

      <Divider />
    </div>
  );
}

import React from "react";
import { Button, Form, Input, InputNumber, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Dish } from "../../../API";

export type IDishForm = {
  name: string;
  description: string;
  price: number;
};

type Props = {
  dish?: Dish;
  loading: boolean;
  onSubmitDishForm: (data: IDishForm) => void;
};

export default function DishForm(props: Props) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [dishForm, setDishForm] = React.useState<IDishForm>();

  React.useEffect(() => {
    if (!props.dish?.id) {
      return;
    }

    form.setFieldsValue({
      name: props.dish?.name,
      description: props.dish?.description,
      price: props.dish?.price,
    });
  }, [props.dish?.id]);

  const onFinish = async (values: IDishForm) => {
    const data = {
      name: values.name,
      description: values.description,
      price: values.price,
    };

    setDishForm(data);
    props.onSubmitDishForm(data);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("onFinishFailed", errorInfo);
  };

  return (
    <Form
      disabled={props.loading}
      form={form}
      layout={"vertical"}
      labelCol={{ span: 8 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label={t("app.create-menu-item.label.dish-name")}
        name={"name"}
        rules={[{ required: true }]}
        required
      >
        <Input
          placeholder={
            dishForm?.name ||
            t("app.create-menu-item.placeholder.dish-name") ||
            ""
          }
        />
      </Form.Item>

      <Form.Item
        label={t("app.create-menu-item.label.dish-description")}
        name={"description"}
        rules={[{ required: false }]}
        required={false}
      >
        <Input.TextArea
          rows={4}
          placeholder={
            dishForm?.description ||
            t("app.create-menu-item.placeholder.dish-description") ||
            ""
          }
        />
      </Form.Item>

      <Form.Item
        label={t("app.create-menu-item.label.price") + " (€)"}
        name={"price"}
        rules={[{ required: true }]}
        required
      >
        <InputNumber
          placeholder={dishForm?.price ? dishForm?.price.toString() : ""}
        />
      </Form.Item>

      <Form.Item>
        <Space split direction="vertical">
          {props.dish?.id && (
            <Typography.Text>
              Gericht ist in der Datenbank hinterlegt
            </Typography.Text>
          )}

          <Button
            block
            htmlType={"submit"}
            loading={props.loading}
            type={"primary"}
          >
            {
              // t("app.create-menu-item.button-submit")
            }
            {props.dish?.id ? "Gericht aktualisieren" : "Gericht hinzufügen"}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

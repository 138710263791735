import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";

import {
  CreateDishOptionItemInput,
  CreateDishOptionItemMutation,
  DeleteDishOptionItemInput,
  DeleteDishOptionItemMutation,
  DishOptionItem,
  GetDishOptionItemQuery,
  GetDishOptionItemQueryVariables,
  ListDishOptionItemsQuery,
  ListDishOptionItemsQueryVariables,
  OnUpdateDishOptionItemSubscription,
  OnUpdateDishOptionItemSubscriptionVariables,
  UpdateDishOptionItemInput,
  UpdateDishOptionItemMutation,
} from "../API";

import { getDishOptionItem, listDishOptionItems } from "../graphql/queries";
import { onUpdateDishOptionItem } from "../graphql/subscriptions";
import {
  createDishOptionItem,
  deleteDishOptionItem,
  updateDishOptionItem,
} from "../graphql/mutations";
import removeTypename from "./helper/remove-typename";
import addBaseFilterToVariables from "./helper/filter-deleted";

export async function fetchDishOptionItemByID(
  variables: GetDishOptionItemQueryVariables
) {
  const payload = await API.graphql<GraphQLQuery<GetDishOptionItemQuery>>(
    graphqlOperation(getDishOptionItem, variables ? variables : {})
  );

  return payload.data?.getDishOptionItem as DishOptionItem;
}

export async function queryList(variables: ListDishOptionItemsQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<ListDishOptionItemsQuery>>(
    graphqlOperation(
      listDishOptionItems,
      variables ? addBaseFilterToVariables(variables) : {}
    )
  );

  return payload.data?.listDishOptionItems?.items as DishOptionItem[];
}

export async function createItem(input: CreateDishOptionItemInput) {
  const payload = await API.graphql<GraphQLQuery<CreateDishOptionItemMutation>>(
    {
      query: createDishOptionItem,
      variables: { input: removeTypename(input) },
    }
  );
  return payload.data?.createDishOptionItem as DishOptionItem;
}

export async function updateDishOptionItemByID(
  input: UpdateDishOptionItemInput
) {
  const origin = await fetchDishOptionItemByID({ id: input.id });
  const inputWithVersion = {
    ...input,
    _version: origin?._version,
  };

  const payload = await API.graphql<GraphQLQuery<UpdateDishOptionItemMutation>>(
    {
      query: updateDishOptionItem,
      variables: { input: removeTypename(inputWithVersion) },
    }
  );
  return payload.data?.updateDishOptionItem as DishOptionItem;
}

export async function deleteItem(input: DeleteDishOptionItemInput) {
  await API.graphql<GraphQLQuery<DeleteDishOptionItemMutation>>({
    query: deleteDishOptionItem,
    variables: { input: removeTypename(input) },
  });
}

export const updateSub = (
  variables: OnUpdateDishOptionItemSubscriptionVariables
) =>
  API.graphql<GraphQLSubscription<OnUpdateDishOptionItemSubscription>>({
    query: onUpdateDishOptionItem,
    variables: removeTypename(variables),
  });

import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";

import {
  AppConfig,
  ListAppConfigsQuery,
  ListAppConfigsQueryVariables,
  OnUpdateAppConfigSubscription,
  OnUpdateAppConfigSubscriptionVariables,
} from "../API";

import { listAppConfigs } from "../graphql/queries";
import { onUpdateAppConfig } from "../graphql/subscriptions";
import addBaseFilterToVariables from "./helper/filter-deleted";

export async function fetchAppConfig(variables: ListAppConfigsQueryVariables) {
  try {
    const payload = await API.graphql<GraphQLQuery<ListAppConfigsQuery>>(
      graphqlOperation(listAppConfigs, addBaseFilterToVariables(variables))
    );

    return (
      payload.data?.listAppConfigs?.items &&
      (payload.data?.listAppConfigs.items[0] as AppConfig)
    );
  } catch (error) {
    console.error("fetchAppConfig", error);
    return null;
  }
}

// Subscribe to update of dishes
export const observeAppConfig = (
  variables: OnUpdateAppConfigSubscriptionVariables
) =>
  API.graphql<GraphQLSubscription<OnUpdateAppConfigSubscription>>({
    query: onUpdateAppConfig,
    variables,
  });

import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Refresh } from "@mui/icons-material";

import { RestaurantAPI } from "../../api-client";
import { useOrdersContext } from "../../context/orders-context";
import { useRestaurantContext } from "../../context/restaurant-context";

export default function OrdersHeader() {
  const restaurantContext = useRestaurantContext();
  const { loading: fetchingOrders, fetchOrders } = useOrdersContext();

  const updateAutoPickUp = async (value: string) => {
    if (!restaurantContext.restaurant?.id) {
      return;
    }

    const updatedRestaurant = await RestaurantAPI.updateRestaurantByID({
      id: restaurantContext.restaurant?.id,
      autoPickUp: value,
    });

    restaurantContext.setRestaurant(updatedRestaurant);
  };

  const buttons = [
    <Button
      key="5"
      variant={
        restaurantContext?.restaurant?.autoPickUp === "5"
          ? "contained"
          : "outlined"
      }
      onClick={() => updateAutoPickUp("5")}
      color="secondary"
    >
      5 min
    </Button>,
    <Button
      key="10"
      variant={
        restaurantContext?.restaurant?.autoPickUp === "10"
          ? "contained"
          : "outlined"
      }
      onClick={() => updateAutoPickUp("10")}
      color="secondary"
    >
      10 min
    </Button>,
    <Button
      key="20"
      variant={
        restaurantContext?.restaurant?.autoPickUp === "20"
          ? "contained"
          : "outlined"
      }
      onClick={() => updateAutoPickUp("20")}
      color="secondary"
    >
      20 min
    </Button>,
    <Button
      key="30"
      variant={
        restaurantContext?.restaurant?.autoPickUp === "30"
          ? "contained"
          : "outlined"
      }
      onClick={() => updateAutoPickUp("30")}
      color="secondary"
    >
      30 min
    </Button>,
  ];

  return (
    <Box>
      <Box flexDirection={"row"} display="flex">
        <Box
          width={"100%"}
          flexDirection={"row"}
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <Typography>Aktuelle Bestellungen</Typography>
            <Typography variant="subtitle2">
              (Es werden ausschließlich Bestellungen angezeigt, die heute
              eingegangen sind.)
            </Typography>
          </Box>
          <Box>
            <LoadingButton
              color={"secondary"}
              disabled={fetchingOrders}
              onClick={() => fetchOrders()}
              size="small"
              startIcon={<Refresh />}
              variant="outlined"
            >
              {fetchingOrders ? "..." : "Aufträge laden"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>

      <Divider style={{ marginBottom: 16, marginTop: 16 }} />

      <Box
        width={"100%"}
        flexDirection={"row"}
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography>Wann ist deine Bestellung abholbereit?</Typography>
        <FormControl size="small">
          <Box>
            <ButtonGroup
              size="small"
              orientation="horizontal"
              aria-label="horizontal outlined button group"
            >
              {buttons}
            </ButtonGroup>
          </Box>
        </FormControl>
      </Box>
      <Divider style={{ marginBottom: 16, marginTop: 16 }} />
    </Box>
  );
}

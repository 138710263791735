import { OrderAPI } from "../api-client";
import { Order, OrderStatus } from "../API";

export default async function updateOrderStatus(
  newStatus: OrderStatus,
  order: Order,
  setOrder: React.Dispatch<React.SetStateAction<Order | undefined>>,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) {
  setLoading && setLoading(true);
  try {
    const updatedOrder = await OrderAPI.updateOrderByID({
      id: order.id,
      status: newStatus,
    });
    setOrder(updatedOrder);
  } catch (error) {
    console.error("ERROR | updateOrderStatus", error);
  } finally {
    setTimeout(() => {
      setLoading && setLoading(false);
    }, 500);
  }
}

import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  List,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Form, message } from "antd";
import { Delete, ExpandMore } from "@mui/icons-material";

import { Dish, DishOptionItem } from "../../../API";
import { DishOptionItemAPI } from "../../../api-client";
import { IDishOptionItemForm } from "../dish-option-item-form";
import { useRestaurantContext } from "../../../context/restaurant-context";
import DishOptionItemForm from "../dish-option-item-form";
import formatPrice from "../../../utils/formatPrice";

type Props = {
  loading: boolean;
  dishOptionID: string | null;
  open: boolean;
  dish?: Dish;
  close: () => void;
  onSuccess: () => void;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  bgcolor: "background.paper",
  //border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CreateDishOptionItemModal(props: Props) {
  const { restaurant } = useRestaurantContext();

  const [dishOptionItemsFormRef] = Form.useForm();

  const [items, setItems] = useState<IDishOptionItemForm[]>([]);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (!props.dishOptionID) {
      return;
    }

    DishOptionItemAPI.queryList({
      filter: { dishOptionID: { eq: props.dishOptionID } },
    }).then((data) => {
      const listItems = data.map((item) => {
        return {
          id: item.id,
          title: item.title,
          description: item.description,
          price: item.price,
        };
      });
      setItems(listItems);
    });
  }, [props.dishOptionID]);

  const onClickSubmit = async () => {
    if (!props.dishOptionID) {
      return message.error("Options-ID fehlt...");
    }

    const originalDishOptionItems = await DishOptionItemAPI.queryList({
      filter: { dishOptionID: { eq: props.dishOptionID } },
    });

    console.debug("originalDishOptionItems", originalDishOptionItems);
    console.debug("items", items);

    const newItems: DishOptionItem[] = [];

    for await (const item of items) {
      const payload = {
        title: item.title,
        description: item.description,
        price: item.price,
        dishOptionID: props.dishOptionID as string,
      };

      // we only create, no need to call update as we dont offer this yet in the frontend
      // user can create and delete DishOptionItems, but not update them

      if (item.id) {
        /*  console.log("has id, so we can update");

        // has id, so we can update
        const updatedItem = await DishOptionItemAPI.updateDishOptionItemByID({
          id: item.id,
          title: payload.title,
          description: payload.description,
          price: payload.price,
        });

        newItems.push(updatedItem); */
      } else {
        console.log("has no id, so we need to create");
        // has no id, so we need to create
        const createdItem = await DishOptionItemAPI.createItem({
          title: payload.title,
          description: payload.description,
          price: payload.price,
          dishOptionID: payload.dishOptionID,
        });
        newItems.push(createdItem);
      }
    }

    console.log("newItems", newItems);

    props.close();
  };

  const onClickDelete = async (item: IDishOptionItemForm) => {
    if (item.id) {
      const toDelete = await DishOptionItemAPI.fetchDishOptionItemByID({
        id: item.id,
      });
      if (toDelete) {
        await DishOptionItemAPI.deleteItem({
          id: toDelete.id,
          _version: toDelete._version,
        });
      }
    }

    setItems((currentState) =>
      currentState.filter((doi) => doi.title !== item.title)
    );
  };

  const onClose = () => {
    props.close();
  };

  return (
    <>
      <Modal open={props.open} onClose={onClose}>
        <Box sx={style}>
          <DishOptionItemForm
            dishOptionItemsFormRef={dishOptionItemsFormRef}
            //disabled={props.loading || !dishOptionForm?.title}
            disabled={props.loading}
            updateDishOptionItemsList={(dishOptionItem) => {
              setItems((currentState) => [...currentState, dishOptionItem]);
            }}
          />

          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              maxHeight: 300,
            }}
            subheader={
              <li>
                <div>
                  Optionen für Gericht <strong>{props.dish?.name}</strong>
                </div>
              </li>
            }
          >
            {items.map((item) => (
              <Accordion
                key={`item-${item.id}`}
                expanded={expanded === item.id}
                onChange={handleChange(item.id || "")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box>
                    <Typography variant="inherit">{item.title}</Typography>
                    <Typography variant="caption">
                      {formatPrice(item.price)}
                    </Typography>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box>
                    <Typography>{item.description}</Typography>
                    {/* <Button onClick={onClickEdit} /> */}
                    <Button
                      color="error"
                      endIcon={<Delete color={"error"} />}
                      onClick={() => onClickDelete(item)}
                    >
                      Löschen
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </List>

          <Button onClick={onClickSubmit}>Fertig</Button>
        </Box>
      </Modal>
    </>
  );
}

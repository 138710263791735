import React, { useEffect } from "react";
import { Select } from "antd";
import i18n from "i18next";
import moment from "moment";

const options = [
  {
    value: "de",
    label: "Deutsch 🇩🇪",
  },
  {
    value: "en",
    label: "English 🇺🇲",
  },
];

const handleChange = (value: { value: string; label: React.ReactNode }) => {
  console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }

  if (value.value === "en") {
    moment.locale("en");
    return i18n.changeLanguage("en");
  }

  moment.locale("de");
  return i18n.changeLanguage("de");
};

const LanguageSelector: React.FC = () => {

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 8,
      }}
    >
      <Select
        labelInValue
        defaultValue={{ value: "de", label: "Deutsch 🇩🇪" }}
        onChange={handleChange}
        options={options}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default LanguageSelector;

import "@aws-amplify/ui-react/styles.css";

import { Authenticator } from "@aws-amplify/ui-react";
import { Button } from "@mui/material";
import { ConfigProvider, Image, Layout, theme as _theme } from "antd";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { AppConfigContextProvider } from "./context/app-config-context";
import { InvoiceContextProvider } from "./context/invoice-context";
import { OrdersContextProvider } from "./context/orders-context";
import {
  RestaurantContextProvider,
  useRestaurantContext,
} from "./context/restaurant-context";
import { SettingsContextProvider } from "./context/settings-context";
import AppRoutes from "./components/app-routes";
import Breadcrumbs from "./components/breadcrumbs";
import InternetSpeedMeter from "./components/internet-speed-meter";
import LoadingOverlay from "./components/loading-overlay";
import S3Image from "./S3Image";
import SideMenu from "./components/side-menu";
import useAppLoader from "./hooks/useAppLoader";

import packageJson from "../package.json";
import NetworkStatusIndicator from "./components/network-status-indicator";
const currentVersion = packageJson.version;

const { Footer } = Layout;

let muiTheme = createTheme({
  palette: {
    primary: {
      main: "#65C2C4",
    },
    secondary: {
      main: "#1E2746",
    },
    error: {
      main: "#BC4646",
    },
  },
});

const theme = {
  token: {
    colorPrimary: "#65C2C4", // #1E2746 #65C2C4
    colorPrimaryHover: "red",
    //colorBgContainer: "#1E2746",
  },
  ..._theme,
};

function AppWithProviders() {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={muiTheme}>
      <Authenticator variation="modal" hideSignUp>
        {({ signOut, user }) => (
          <>
            <main
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 16,
              }}
            >
              <h1>Hello {user?.attributes?.email}</h1>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  signOut && signOut();

                  navigate("/");
                }}
              >
                ABMELDEN
              </Button>
            </main>

            {user?.attributes?.email && (
              <AppConfigContextProvider>
                <SettingsContextProvider>
                  <RestaurantContextProvider>
                    <OrdersContextProvider>
                      <InvoiceContextProvider>
                        <App />
                      </InvoiceContextProvider>
                    </OrdersContextProvider>
                  </RestaurantContextProvider>
                </SettingsContextProvider>
              </AppConfigContextProvider>
            )}
          </>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}

function SiderLogo() {
  const context = useRestaurantContext();

  return (
    <div style={{ minHeight: 180 }}>
      {context.restaurant?.image?.logoKey ? (
        <S3Image
          alt="logo"
          imgKey={context.restaurant?.image?.logoKey}
          style={{
            width: "95%",
          }}
          //preview={false}
        />
      ) : (
        <Image
          alt="logo-static"
          style={{ width: "95%" }}
          src={require("../src/assets/logo.png")}
          preview={false}
        />
      )}
    </div>
  );
}

function App() {
  const context = useRestaurantContext();

  const { Header, Sider, Content } = Layout;

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <LoadingOverlay
        loading={Boolean(context.restaurant && context.loading)}
      />
      <ConfigProvider theme={theme}>
        <Layout>
          <Sider theme="light" style={styles.sider}>
            <SiderLogo />
            {context.restaurant?.loggingEnabled && <InternetSpeedMeter />}
            <SideMenu />
          </Sider>

          <Layout>
            <Header
              style={{
                padding: 24,
                paddingBottom: 48,
                background: colorBgContainer,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Breadcrumbs />
              </Box>
            </Header>
            <Content
              style={{
                //margin: "24px 16px",
                padding: 24,
                minHeight: 280,
                background: colorBgContainer,
              }}
            >
              <AppRoutes />
            </Content>

            <Footer style={{ textAlign: "center" }}>
              v{currentVersion} | Tasty Delivery Restaurant Dashboard ©2022
            </Footer>
          </Layout>
        </Layout>
      </ConfigProvider>
    </>
  );
}

function WithAppLoader() {
  const { appInitialized, initialClearDone, ready } = useAppLoader();

  return (
    <>
      <NetworkStatusIndicator />
      <LoadingOverlay
        loading={initialClearDone && !ready}
        text={
          !appInitialized
            ? "Einen Augenblick bitte, das Dashboard wird initialisiert"
            : ""
        }
      />
      {appInitialized && <AppWithProviders />}
    </>
  );
}

const styles = {
  sider: {
    height: "100vh",
  },
};

export default WithAppLoader;

// Create a context
import React, { createContext, ReactElement, useEffect } from "react";

import { AppConfig } from "../API";
import { AppConfigAPI } from "../api-client";

export interface IAppConfigContext {
  loading: boolean;
  appConfig: AppConfig | null;
}

const AppConfigContext: React.Context<{}> = createContext<
  IAppConfigContext | {}
>({});

const AppConfigContextProvider = ({ children }: { children: ReactElement }) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const [appConfig, setAppConfig] = React.useState<AppConfig | null>(null);

  const fetchAppConfig = () => {
    setLoading(true);

    AppConfigAPI.fetchAppConfig({})
      .then(async (data) => {
        data && setAppConfig(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAppConfig();
  }, []);

  return (
    <AppConfigContext.Provider
      value={{
        appConfig,
        loading,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfigContext = (): IAppConfigContext =>
  React.useContext(AppConfigContext) as IAppConfigContext;

export { AppConfigContext, AppConfigContextProvider };

import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";

import {
  Restaurant,
  GetRestaurantQuery,
  GetRestaurantQueryVariables,
  OnUpdateRestaurantSubscription,
  OnUpdateRestaurantSubscriptionVariables,
  UpdateRestaurantInput,
  UpdateRestaurantMutation,
  ListRestaurantsQueryVariables,
  ListRestaurantsQuery,
  CreateRestaurantInput,
  CreateRestaurantMutation,
} from "../API";

import { getRestaurant, listRestaurants } from "../graphql/queries";
import { onUpdateRestaurant } from "../graphql/subscriptions";
import { createRestaurant, updateRestaurant } from "../graphql/mutations";
import removeTypename from "./helper/remove-typename";
import addBaseFilterToVariables from "./helper/filter-deleted";

export async function fetchRestaurantByID(
  variables: GetRestaurantQueryVariables
) {
  const payload = await API.graphql<GraphQLQuery<GetRestaurantQuery>>(
    graphqlOperation(getRestaurant, variables ? variables : {})
  );

  return payload.data?.getRestaurant as Restaurant;
}
export async function fetchRestaurantByAdminID(
  variables: ListRestaurantsQueryVariables
) {
  const payload = await API.graphql<GraphQLQuery<ListRestaurantsQuery>>(
    graphqlOperation(
      listRestaurants,
      variables ? addBaseFilterToVariables(variables) : {}
    )
  );

  return (
    payload.data?.listRestaurants?.items &&
    (payload.data?.listRestaurants?.items[0] as Restaurant)
  );
}

export async function createItem(input: CreateRestaurantInput) {
  const payload = await API.graphql<GraphQLQuery<CreateRestaurantMutation>>({
    query: createRestaurant,
    variables: { input: removeTypename(input) },
  });
  return payload.data?.createRestaurant as Restaurant;
}

export async function updateRestaurantByID(input: UpdateRestaurantInput) {
  const origin = await fetchRestaurantByID({ id: input.id });
  const inputWithVersion = {
    ...input,
    _version: origin?._version,
  };

  const payload = await API.graphql<GraphQLQuery<UpdateRestaurantMutation>>({
    query: updateRestaurant,
    variables: { input: removeTypename(inputWithVersion) },
  });
  return payload.data?.updateRestaurant as Restaurant;
}

export const observeRestaurantes = (
  variables: OnUpdateRestaurantSubscriptionVariables
) =>
  API.graphql<GraphQLSubscription<OnUpdateRestaurantSubscription>>({
    query: onUpdateRestaurant,
    variables,
  });

// Create a context
import React, { createContext, ReactElement, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { message } from "antd";

import { Restaurant, RestaurantStatus } from "../API";
import { RestaurantAPI } from "../api-client";

export interface IRestaurantContext {
  authUser: CognitoUser | any;
  fetchRestaurantByAdminSub: () => void;
  loading: boolean;
  restaurant: Restaurant | null;
  setRestaurant: (restaurant: Restaurant | null) => void;
  sub?: string;
  updateRestaurantStatus: (status: RestaurantStatus) => void;
  updateRestaurantLoggingStatus: (
    loggingEnabled: Restaurant["loggingEnabled"]
  ) => void;
  updateRestaurantBillingDetails: (
    billingDetails: Restaurant["billingDetails"]
  ) => void;
}

const RestaurantContext: React.Context<{}> = createContext<
  IRestaurantContext | {}
>({});

const RestaurantContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [authUser, setAuthUser] = useState<CognitoUser | any>();
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);

  const sub = authUser?.attributes?.sub;

  const fetchRestaurantByAdminSub = () => {
    setLoading(true);

    RestaurantAPI.fetchRestaurantByAdminID({
      filter: { adminSub: { eq: sub } },
    })
      .then((r) => {
        if (r) {
          console.log("setRestaurant", r);
          setRestaurant(r);
        } else {
          message.warning("Kein Restaurant gefunden");
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  async function updateRestaurantBillingDetails(
    billingDetails: Restaurant["billingDetails"]
  ) {
    if (!restaurant?.id) {
      return message.warning("Keine Restaurant ID gefunden...");
    }

    const updatedRestaurant = await RestaurantAPI.updateRestaurantByID({
      id: restaurant.id,
      billingDetails,
    });

    setRestaurant(updatedRestaurant);

    message.success("Rechnungsanschrift aktualisiert");
  }

  async function updateRestaurantStatus(status: RestaurantStatus) {
    if (!restaurant?.id) {
      return message.warning("Keine Restaurant ID gefunden...");
    }

    const updatedRestaurant = await RestaurantAPI.updateRestaurantByID({
      id: restaurant.id,
      status,
    });

    setRestaurant(updatedRestaurant);

    message.success("Der Status des Restaurants ist nun " + status);
  }

  async function updateRestaurantLoggingStatus(
    loggingEnabled: Restaurant["loggingEnabled"]
  ) {
    if (!restaurant?.id) {
      return message.warning("Keine Restaurant ID gefunden...");
    }

    const updatedRestaurant = await RestaurantAPI.updateRestaurantByID({
      id: restaurant.id,
      loggingEnabled,
    });

    setRestaurant(updatedRestaurant);

    message.success(
      "Logging ist nun " + updatedRestaurant.loggingEnabled
        ? "aktiv"
        : "inaktiv"
    );
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(setAuthUser);
  }, []);

  useEffect(() => {
    if (!sub) {
      return;
    }
    fetchRestaurantByAdminSub();
  }, [sub]);

  return (
    <RestaurantContext.Provider
      value={{
        authUser,
        fetchRestaurantByAdminSub,
        loading,
        restaurant,
        setRestaurant,
        sub,
        updateRestaurantStatus,
        updateRestaurantLoggingStatus,
        updateRestaurantBillingDetails,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};

export const useRestaurantContext = (): IRestaurantContext =>
  React.useContext(RestaurantContext) as IRestaurantContext;

export { RestaurantContext, RestaurantContextProvider };

export function readFileAsync(
  file: File
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function loadImgAsync(imgSrc: string) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = reject;
    img.src = imgSrc;
  });
}

export function imgToBlobAsync(img: any, canvas: any): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const ctxMain = canvas.getContext("2d");
    ctxMain.drawImage(img, 0, 0, canvas.width, canvas.height);
    ctxMain.canvas.toBlob(async (blob: Blob | PromiseLike<Blob>) => {
      resolve(blob);
    }, "image/*");
  });
}

export function createCanvas(img: any, maxSize: number) {
  const canvas = document.createElement("canvas");
  if (img.width > img.height) {
    const widthMain = maxSize;
    const scaleFactorMain = widthMain / img.width;
    canvas.width = widthMain;
    canvas.height = img.height * scaleFactorMain;
    console.log({ widthMain });
  } else {
    const heightMain = maxSize;
    const scaleFactorMain = heightMain / img.height;
    canvas.width = img.width * scaleFactorMain;
    canvas.height = heightMain;
    console.log({ heightMain });
  }
  return canvas;
}

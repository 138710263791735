import { useEffect, useState } from "react";
import {
  ButtonGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { Loader } from "@aws-amplify/ui-react";
import { message } from "antd";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import moment from "moment";

import { calculateRestaurantInvoice } from "../../graphql/mutations";
import { RestaurantWeeklyBill } from "../../models";
import { useInvoiceContext } from "../../context/invoice-context";
import { useRestaurantContext } from "../../context/restaurant-context";
import BillingDetailsForm from "../../components/billing-details-form";
import InvoicePDF from "./invoice-pdf";
import sleep from "../../utils/sleep";
import { ArrowRight, ChevronRight } from "@mui/icons-material";

export default function InvoicesScreen() {
  const [loading, setLoading] = useState(false);
  const [downloadingKey, setDownloadingKey] = useState("");
  const [weeks, setWeeks] = useState<string[]>([]);

  const { restaurant } = useRestaurantContext();
  const invoiceContext = useInvoiceContext();

  const [storageInvoices, setStorageInvoices] = useState<
    S3ProviderListOutputItem[]
  >([]);

  useEffect(() => {
    if (!restaurant?.id) {
      return;
    }

    fetchStorageInvoices(restaurant?.id);
  }, [restaurant?.id]);

  const fetchStorageInvoices = (restaurantID: string) => {
    setLoading(true);
    Storage.list("invoices/" + restaurantID + "/", {
      level: "public",
      pageSize: "ALL",
    })
      .then(({ results, hasNextToken }) => {
        console.log("Storage", { results });

        setStorageInvoices(results);
      })
      .catch((err) => console.error("Storage error", err))
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      );
  };

  useEffect(() => {
    if (!storageInvoices) {
      return;
    }

    let startDate = moment("2023-10-02").startOf("week").format("YYYY-MM-DD");
    let endDate = moment(new Date()).startOf("week").format("YYYY-MM-DD");

    const weeks = [];
    while (startDate <= endDate) {
      weeks.push(startDate);
      startDate = moment(startDate).add(7, "days").format("YYYY-MM-DD");
    }

    setWeeks(weeks.reverse());

    console.log({ weeks });
  }, [storageInvoices]);

  async function onClickOpenPDF(_hasInvoice: S3ProviderListOutputItem) {
    console.log("onClickOpenPDF", _hasInvoice);
    if (!_hasInvoice.key) {
      return;
    }

    console.log("trying to download: ", _hasInvoice.key);

    setDownloadingKey(_hasInvoice.key);

    const result = await Storage.get(_hasInvoice.key, {
      download: true,
      level: "public",
      contentType: "application/json",
      cacheControl: "no-cache",
      progressCallback(progress) {
        console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
      },
    });

    // @ts-ignore
    const text = await result.Body.text();

    const weeklyInvoice = JSON.parse(text) as RestaurantWeeklyBill;

    const start = _hasInvoice.key
      ?.substring(_hasInvoice.key.lastIndexOf("/") + 1)
      .split("---")[0];
    const end = _hasInvoice.key
      ?.substring(_hasInvoice.key.lastIndexOf("/") + 1)
      .split("---")[1]
      .replace(".json", "");

    const dateRange =
      moment(start).format("DD.MM.YYYY") +
      " - " +
      moment(end).format("DD.MM.YYYY");

    console.log({ weeklyInvoice, dateRange });

    invoiceContext.onSetupInvoiceModal(weeklyInvoice, dateRange);

    setDownloadingKey("");
  }

  async function generateAllInvoices() {
    if (!restaurant?.id) {
      return;
    }

    for await (const week of weeks) {
      setLoading(true);
      await sleep(500);

      const payload = {
        restaurantID: restaurant.id,
        startDate: moment(week + "_02:00", "YYYY-MM-DD_hh:mm")
          .utc()
          .toISOString(),
        endDate: moment(week + "_01:59", "YYYY-MM-DD_hh:mm")
          .endOf("week")
          .utc()
          .toISOString(),
      };

      console.log("onClick calculateRestaurantInvoice", { payload });
      API.graphql(graphqlOperation(calculateRestaurantInvoice, payload));

      message.success(
        "Rechnung wird generiert und sollte innerhalb weniger sekunden sichtbar sein"
      );

      setLoading(false);
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Box alignItems={"center"} justifyContent={"center"}>
      <BillingDetailsForm />

      <List
        dense
        style={{ opacity: loading ? 0.3 : 1, marginTop: 16 }}
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
        }}
      >
        {/*        <ListSubheader>
          {loading ? (
            <Loader />
          ) : (
            <ButtonGroup>
              <Button
                disabled={loading || !restaurant?.id}
                onClick={() =>
                  restaurant?.id && fetchStorageInvoices(restaurant?.id)
                }
              >
                Rechnungen laden
              </Button>
              <Button
                disabled={false} //{loading}
                onClick={generateAllInvoices}
              >
                Alle Rechnungen neu generieren
              </Button>
            </ButtonGroup>
          )}
        </ListSubheader> */}

        {weeks.map((week) => {
          const hasInvoice = storageInvoices.find((invoice) => {
            const start = moment(
              invoice.key
                ?.substring(invoice.key.lastIndexOf("/") + 1)
                .split("---")[0]
            ).format("YYYY-MM-DD");
            console.log("start", start);
            return start === week;
          });

          return (
            <ListItem>
              <ListItemButton
                divider
                disabled={!hasInvoice || hasInvoice.key === downloadingKey}
                onClick={() => hasInvoice && onClickOpenPDF(hasInvoice)}
              >
                <ListItemText>
                  {moment(week).format("DD.MM.YYYY")} -{" "}
                  {moment(week).endOf("week").format("DD.MM.YYYY")}
                </ListItemText>

                {/*  {hasInvoice ? (
                <ButtonGroup size="small" disabled={loading}>
                  {restaurant?.id && (
                    <GenerateButton
                      week={week}
                      restaurantID={restaurant.id}
                      reloadInvoices={() => fetchStorageInvoices(restaurant.id)}
                    />
                  )}

                  <Button
                    variant="contained"
                    color="info"
                    disabled={hasInvoice.key === downloadingKey}
                    onClick={() => onClickOpenPDF(hasInvoice)}
                  >
                    PDF
                  </Button>
                </ButtonGroup>
              ) : (
                restaurant?.id && (
                  <GenerateButton
                    disabled={loading}
                    week={week}
                    restaurantID={restaurant.id}
                    reloadInvoices={() => fetchStorageInvoices(restaurant?.id)}
                  />
                )
              )} */}
              </ListItemButton>
              <ListItemIcon>
                <ChevronRight />
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>

      <Modal
        open={invoiceContext.showPDFInvoice}
        onClose={invoiceContext.closePDFInvoiceModal}
      >
        <Box sx={style}>
          <InvoicePDF />
        </Box>
      </Modal>
    </Box>
  );
}

function GenerateButton({
  week,
  restaurantID,
  reloadInvoices,
  disabled,
}: {
  week: string;
  restaurantID: string;
  reloadInvoices?: () => void;
  disabled?: boolean;
}) {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    const payload = {
      restaurantID: restaurantID,
      startDate: moment(week + "_02:00", "YYYY-MM-DD_hh:mm")
        .utc()
        .toISOString(),
      endDate: moment(week + "_01:59", "YYYY-MM-DD_hh:mm")
        .endOf("week")
        .utc()
        .toISOString(),
    };

    console.log("calculateRestaurantInvoice start", { payload });
    try {
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(calculateRestaurantInvoice, payload)
      );
      console.log("calculateRestaurantInvoice", { response });

      message.success(
        "Rechnung wird generiert und sollte innerhalb weniger sekunden sichtbar sein"
      );
    } catch (error: any) {
      console.error("calculateRestaurantInvoice", { error });
      message.error(error.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
        reloadInvoices && reloadInvoices();
      }, 10000);
    }
  };
  return (
    <Button
      size="small"
      disabled={false} //disabled || loading}
      variant="contained"
      color="warning"
      onClick={onClick}
    >
      {loading ? <Loader /> : "Generieren"}
    </Button>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  //border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

import { Box, Modal } from "@mui/material";

import { Dish } from "../../../API";
import { useRestaurantContext } from "../../../context/restaurant-context";
import DishOptionCreateForm, {
  DishOptionCreateFormOverridesProps,
} from "../../../ui-components/DishOptionCreateForm";
import DishOptionUpdateForm, {
  DishOptionUpdateFormOverridesProps,
} from "../../../ui-components/DishOptionUpdateForm";

type Props = {
  loading: boolean;
  dishOptionID: string | null;
  dish?: Dish;
  createDishOpen: boolean;
  setCreateDishOpen: (open: boolean) => void;
  onSuccessCreateDishOption: () => void;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const defaultOverrides = {
  title: {
    label: "Titel",
  },
  description: {
    label: "Beschreibung",
  },
  multipleSelection: {
    label: "Mehrfachauswahl",
  },
  obligatory: {
    label: "Pflichtfeld",
  },
  dishIDs: {
    label: "Zugehörige GerichtIDs",
    isDisabled: false,
    size: "small",
  },
};

export default function CreateDishOptionModal(props: Props) {
  const { restaurant } = useRestaurantContext();

  const onClose = () => {
    props.setCreateDishOpen(false);
  };

  return (
    <>
      <Modal open={props.createDishOpen} onClose={onClose}>
        <Box sx={style}>
          {!props.dishOptionID ? (
            <DishOptionCreateForm
              overrides={
                {
                  ...defaultOverrides,
                  restaurantID: {
                    value: restaurant?.id,
                    isDisabled: true,
                    label: restaurant?.name,
                  },
                } as DishOptionCreateFormOverridesProps
              }
              onSuccess={props.onSuccessCreateDishOption}
              onSubmit={(fields) => {
                // We need to call onSubmit callback
                // if we dont do this restaurantID will always ne undefined

                return {
                  title: fields.title,
                  description: fields.description,
                  dishIDs: fields.dishIDs,
                  multipleSelection: fields.multipleSelection,
                  restaurantID: restaurant?.id,
                };
              }}
              onError={(fields) => {
                console.error("DishOptionCreateForm", fields);
              }}
            />
          ) : (
            <DishOptionUpdateForm
              id={props.dishOptionID}
              overrides={
                {
                  ...defaultOverrides,
                  restaurantID: {
                    value: restaurant?.id,
                    isDisabled: true,
                    label: restaurant?.name,
                  },
                } as DishOptionUpdateFormOverridesProps
              }
              onSuccess={props.onSuccessCreateDishOption}
              onSubmit={(fields) => {
                // We need to call onSubmit callback
                // if we dont do this restaurantID will always ne undefined

                return {
                  title: fields.title,
                  description: fields.description,
                  dishIDs: fields.dishIDs,
                  multipleSelection: fields.multipleSelection,
                  restaurantID: restaurant?.id,
                };
              }}
              onError={(fields) => {
                console.error("DishOptionUpdateForm", fields);
              }}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

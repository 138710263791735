import { Button, Card } from "antd";
import { Box } from "@mui/material";

import Lottie from "lottie-react";
import errorLottie from "./error-lottie.json";

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  return (
    <Card>
      <Box
        flexDirection={"row"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box>
          <Lottie
            animationData={errorLottie}
            loop={true}
            // style={{ width: "33%" }}
          />
        </Box>

        <Box>
          <div role="alert">
            <h3>Es ist leider ein Fehler aufgetreten:</h3>
            <pre>{error.message}</pre>
            <Button onClick={resetErrorBoundary}>Erneut versuchen</Button>
          </div>
        </Box>
      </Box>
    </Card>
  );
}

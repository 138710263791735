import React, { createContext, ReactElement, useEffect, useState } from "react";
import moment from "moment";

import { Order, OrderStatus } from "../API";
import { OrderAPI } from "../api-client";
import { usePersistentStorageValue } from "../utils/usePersistentStorageValue";
import { useRestaurantContext } from "./restaurant-context";
import { isArray } from "lodash";

const start = moment().utc().startOf("day").toISOString();
const end = moment().utc().endOf("day").toISOString();
export interface IOrdersContext {
  loading: boolean;
  orders: Order[];
  fetchOrders: () => Promise<void>;
  updateOrderStatus: (orderStatus: OrderStatus) => Promise<void>;
  markOrderAsRead: (orderId: string) => Promise<void>;
}

const OrdersContext: React.Context<{}> = createContext<IOrdersContext | {}>({});

const OrdersContextProvider = ({ children }: { children: ReactElement }) => {
  const { restaurant } = useRestaurantContext();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [orders, setOrders] = usePersistentStorageValue<Order[]>("orders", []);
  // const [orders, setOrders] = useState<Order[]>([]);

  async function markOrderAsRead(orderID: string) {
    await OrderAPI.updateOrderByID({ id: orderID, read: true });
  }

  function updateArray(newItem: Order) {
    // Find the index of the existing item in the array
    const existingItemIndex = orders.findIndex(
      (item: Order) => item.id === newItem.id
    );

    // If the item doesn't exist, add it to the array
    if (existingItemIndex === -1) {
      return [...orders, newItem];
    }

    const updatedArray = orders.map((item, index) =>
      index === existingItemIndex ? newItem : item
    );

    // If the item exists, replace it with the new item
    return updatedArray;
  }

  function updateOrders(items: Order[]) {
    console.log("updateOrders", { orders, items });

    const sortedByCreationDate = items
      .sort(
        // @ts-ignore
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      )
      .reverse();

    const _orders = sortedByCreationDate || items;

    setOrders(_orders);
  }

  function fetchOrders() {
    const variables = {
      filter: {
        and: [
          { _deleted: { ne: true } },
          { createdAt: { gt: start } },
          { orderRestaurantId: { eq: restaurant?.id } },
          {
            or: [
              { status: { eq: OrderStatus.ACCEPTED } },
              { status: { eq: OrderStatus.COOKING } },
              { status: { eq: OrderStatus.NEW } },
              { status: { eq: OrderStatus.READY_FOR_PICKUP } },
            ],
          },
        ],
      },
    };

    OrderAPI.fetchOrders(variables)
      .then((items) => {
        console.log("FETCH ORDERS", { items });
        updateOrders(items || []);
      })
      .catch((error) => {
        console.error("FETCH ORDERS ERROR", { error });
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 1500)
      );
    setLoading(true);
  }

  useEffect(() => {
    if (!restaurant?.id) {
      return;
    }

    fetchOrders();

    /**
     * Observe Model Order of type INSERT & matching to restaurant id
     * Query Order filtered by
     * ACCEPTED, NEW, COOKING AND READY_FOR_PICKUP ORDERS
     */

    const subscription = OrderAPI.observeOrders({
      filter: {
        and: [
          { _deleted: { ne: true } },
          { createdAt: { gt: start } },
          { orderRestaurantId: { eq: restaurant?.id } },
        ],
      },
    }).subscribe({
      next: ({ value }) => {
        console.log("OBSERVE ORDERS TRIGGERED", { value });

        const updatedOrder = value?.data?.onUpdateOrder as Order;
        const newOrders = updateArray(updatedOrder);

        updateOrders(newOrders || []);
      },
      error: (error) => console.warn(error),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [restaurant?.id]);

  const allowedStatuses: OrderStatus[] = [
    OrderStatus.ACCEPTED,
    OrderStatus.COOKING,
    OrderStatus.NEW,
    OrderStatus.READY_FOR_PICKUP,
  ];

  return (
    <OrdersContext.Provider
      value={{
        loading,
        markOrderAsRead,
        fetchOrders,
        orders: (isArray(orders) ? orders : []).filter((order) =>
          allowedStatuses.includes(order.status)
        ),
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrdersContext = (): IOrdersContext =>
  React.useContext(OrdersContext) as IOrdersContext;

export { OrdersContext, OrdersContextProvider };

import { useEffect, useState } from "react";

import { API } from "aws-amplify";
import { Box, Typography } from "@mui/material";
import { GraphQLQuery } from "@aws-amplify/api";
import { List } from "antd";
import { LoadingButton } from "@mui/lab";
import { Refresh } from "@mui/icons-material";

import { Dish, OrderDish, OrderStatus } from "../../API";
import { EagerDishOption, LazyDishOptionItem } from "../../models";
import { getOrderDish } from "../../graphql/queries";
import { GetOrderDishQuery, GetOrderDishQueryVariables } from "../../API";
import formatPrice from "../../utils/formatPrice";

function ItemsList({
  items,
}: {
  items?: (LazyDishOptionItem | null)[] | null;
}) {
  return (
    <>
      {items ? (
        items.map((item) => {
          return (
            <Typography key={item?.id} variant="inherit">
              - {item?.title} ({formatPrice(item?.price!)})
            </Typography>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
}
export default function OrderDishItem({
  orderDishItem,
  orderStatus,
}: {
  orderDishItem: OrderDish;
  orderStatus?: OrderStatus;
}) {
  const [theDish, setTheDish] = useState<Dish>();
  const [theOrderDish, setTheOrderDish] =
    useState<GetOrderDishQuery["getOrderDish"]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dishError, setDishError] = useState<boolean | string>(false);

  useEffect(() => {
    if (!orderDishItem.id) {
      return;
    }

    fetchGraphlQL();
  }, [orderDishItem.id]);

  // If orderDish.Dish throws an error or the response is empty then we will fetch via
  async function fetchGraphlQL() {
    setDishError(false);
    setLoading(true);

    try {
      const variables: GetOrderDishQueryVariables = {
        id: orderDishItem.id,
      };

      const response = await API.graphql<GraphQLQuery<GetOrderDishQuery>>({
        query: getOrderDish,
        variables: variables,
      });

      setTheOrderDish(response.data?.getOrderDish);

      const _theDish = response.data?.getOrderDish?.Dish;

      if (_theDish?.id) {
        setTheDish(_theDish as unknown as Dish);
      } else {
        setDishError("Dish is Empty");
      }
    } catch (e: any) {
      setDishError("Dish is Empty " + (e.message || "ERROR"));
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }

  return (
    <>
      {dishError && (
        <Box flexDirection={"column"} display="flex" marginTop={4} width={200}>
          <Typography variant="inherit" color={"error"}>
            Ein Gericht konnte nicht richtig geladen werden...
          </Typography>
          {typeof dishError === "string" && (
            <Typography variant="inherit" color={"error"}>
              {dishError}
            </Typography>
          )}
          <LoadingButton
            loading={loading}
            variant="outlined"
            size="small"
            color="error"
            onClick={fetchGraphlQL}
            startIcon={<Refresh />}
            style={{ marginBottom: 8, marginTop: 8 }}
          >
            Laden
          </LoadingButton>
        </Box>
      )}
      <List.Item
        key={theOrderDish?.id}
        style={{
          backgroundColor:
            orderStatus === OrderStatus.DECLINED_BY_RESTAURANT
              ? "lightgrey"
              : "white",
          opacity:
            orderStatus === OrderStatus.DECLINED_BY_RESTAURANT ? 0.33 : 1,
        }}
      >
        <Box>
          <Box my={2}>
            <Typography style={{ textDecorationLine: "underline" }}>
              Gericht
            </Typography>
            <Typography>
              {theOrderDish?.quantity}x <strong>{theDish?.name}</strong>
            </Typography>
            {orderDishItem.note && (
              <div
                style={{
                  padding: 8,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              >
                <Typography fontWeight={500}>Anmerkung zum Gericht:</Typography>
                <Typography fontWeight={300}>{orderDishItem.note}</Typography>
              </div>
            )}
          </Box>
          {theOrderDish?.selectedDishOptionsJSON &&
            JSON.parse(theOrderDish?.selectedDishOptionsJSON).length > 0 && (
              <Box my={2}>
                <Typography style={{ textDecorationLine: "underline" }}>
                  Optionen
                </Typography>
                {JSON.parse(theOrderDish.selectedDishOptionsJSON)?.map(
                  (option: EagerDishOption) => {
                    return (
                      <Box key={option.id}>
                        <Typography>{option.title}</Typography>
                        <ItemsList items={option.items} />
                      </Box>
                    );
                  }
                )}
              </Box>
            )}
        </Box>

        <Typography>{formatPrice(theDish?.price!)}</Typography>
      </List.Item>
    </>
  );
}

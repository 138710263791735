import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  OrderDish,
  CreateOrderDishInput,
  CreateOrderDishMutation,
  DeleteOrderDishInput,
  DeleteOrderDishMutation,
  GetOrderDishQuery,
  GetOrderDishQueryVariables,
  ListOrderDishesByOrderIDQuery,
  ListOrderDishesByOrderIDQueryVariables,
  ListOrderDishesQuery,
  ListOrderDishesQueryVariables,
  UpdateOrderDishInput,
  UpdateOrderDishMutation,
} from "../API";

import {
  createOrderDish,
  deleteOrderDish,
  updateOrderDish,
} from "../graphql/mutations";
import {
  getOrderDish,
  listOrderDishes,
  listOrderDishesByOrderID,
} from "../graphql/queries";
import addBaseFilterToVariables from "./helper/filter-deleted";

export async function queryItem(variables: GetOrderDishQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<GetOrderDishQuery>>(
    graphqlOperation(getOrderDish, variables ? variables : {})
  );
  return payload.data?.getOrderDish;
}
export async function queryList(variables: ListOrderDishesQueryVariables) {
  console.log({ variables: JSON.stringify(variables) });
  const payload = await API.graphql<GraphQLQuery<ListOrderDishesQuery>>(
    graphqlOperation(
      listOrderDishes,
      variables ? addBaseFilterToVariables(variables) : {}
    )
  );
  return payload.data?.listOrderDishes?.items as OrderDish[];
}
export async function queryListByOrderID(
  variables: ListOrderDishesByOrderIDQueryVariables
) {
  const payload = await API.graphql<
    GraphQLQuery<ListOrderDishesByOrderIDQuery>
  >(
    graphqlOperation(
      listOrderDishesByOrderID,
      variables ? addBaseFilterToVariables(variables) : {}
    )
  );
  return payload.data?.listOrderDishesByOrderID?.items as OrderDish[];
}
export async function createItem(input: CreateOrderDishInput) {
  const payload = await API.graphql<GraphQLQuery<CreateOrderDishMutation>>({
    query: createOrderDish,
    variables: { input },
  });

  return payload.data?.createOrderDish;
}
export async function updateItem(input: UpdateOrderDishInput) {
  const payload = await API.graphql<GraphQLQuery<UpdateOrderDishMutation>>({
    query: updateOrderDish,
    variables: { input },
  });
  return payload.data?.updateOrderDish;
}
export async function deleteItem(input: DeleteOrderDishInput) {
  await API.graphql<GraphQLQuery<DeleteOrderDishMutation>>({
    query: deleteOrderDish,
    variables: { input },
  });
}

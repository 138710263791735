import React, { useEffect } from "react";
import { Button, Divider, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Card, Grid } from "@mui/material";

import { Dish, DishOption, DishType } from "../../API";
import { DishAPI, DishOptionAPI, DishOptionItemAPI } from "../../api-client";
import { IDishOptionForm } from "./dish-option-form";
import { useRestaurantContext } from "../../context/restaurant-context";
import DishAllergens from "../../components/dish-allergens";
import DishCategoryList from "./dish-category-list";
import DishForm, { IDishForm } from "./dish-form";
import DishOptionsList from "./dish-options-list";
import SelectDishType from "../../components/select-dish-type";

export default function CreateMenuItem() {
  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();

  const { restaurant } = useRestaurantContext();

  const [dish, setDish] = React.useState<Dish>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [selectedDishType, setSelectedDishType] = React.useState<DishType>(
    DishType.FOOD
  );

  const isFood = selectedDishType === DishType.FOOD;
  const isDrink =
    selectedDishType === DishType.DRINK_ALCOHOLIC ||
    selectedDishType === DishType.DRINK_NON_ALCOHOLIC;

  /**
   * QUERY DISH FOR EDITING (COMING FROM MENU)
   */
  useEffect(() => {
    if (!id) {
      return;
    }
    DishAPI.fetchDishByID({ id }).then(setDish);
  }, [id]);

  useEffect(() => {
    if (!dish) {
      return;
    }
    console.log("dish.dishType", dish.dishType);
    setSelectedDishType(dish.dishType || DishType.FOOD);
  }, [dish]);

  async function onSubmitDishForm(dishForm: IDishForm) {
    await onUpdateDish({ dishForm });
  }

  async function onSubmitDishOptionForm(dishOptionForm: IDishOptionForm) {
    await onUpdateDish({ dishOptionForm });
  }

  const onUpdateDish = async ({
    dishForm,
    dishOptionForm,
  }: {
    dishForm?: IDishForm;
    dishOptionForm?: IDishOptionForm;
  }) => {
    setLoading(true);

    if (!restaurant?.id) {
      return message.error(t("app.messages.error.no-restaurant-id"));
    }

    try {
      if (!dish && dishForm) {
        await createDish(dishForm);
      } else {
        await updateDish(dishForm, dishOptionForm);
      }
    } catch (e: any) {
      message.error(
        "Dein Gericht konnte nicht angelegt oder aktualisiert werden...."
      );
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 750);
    }
  };

  async function createDish(dishForm: IDishForm) {
    if (!restaurant?.id) {
      return message.error(t("app.messages.error.no-restaurant-id"));
    }

    if (!dishForm) {
      return message.error(t("app.messages.error.no-restaurant-id"));
    }

    const createdDish = await DishAPI.createItem({
      name: dishForm.name,
      description: dishForm.description,
      price: dishForm.price,
      restaurantID: restaurant?.id,
      dishType: selectedDishType,
    });

    message.success("Gericht erfolgreich angelegt");
    setDish(createdDish);
  }

  async function updateDish(
    dishForm?: IDishForm,
    dishOptionForm?: IDishOptionForm
  ) {
    if (!dish?.id) {
      return message.error("Bitte zuerst ein Gericht anlegen");
    }

    if (dishOptionForm) {
      await createDishOption(dish?.id, dishOptionForm);
    }

    const updatedDish = await DishAPI.updateDishByID({
      id: dish?.id,
      name: dishForm?.name,
      description: dishForm?.description,
      price: dishForm?.price,
      dishType: selectedDishType,
    });

    message.success("Gericht erfolgreich aktualisiert");
    setDish(updatedDish);
  }

  async function createDishOption(
    dishID: string,
    dishOptionForm: IDishOptionForm
  ): Promise<DishOption> {
    const dishOptionResult = await DishOptionAPI.createItem({
      title: dishOptionForm?.title,
      description: dishOptionForm?.description,
      multipleSelection: !!dishOptionForm?.multipleSelection,
      restaurantID: restaurant?.id,
      orderDishID: dishID,
    });

    for await (const item of dishOptionForm.items || []) {
      await DishOptionItemAPI.createItem({
        dishOptionID: dishOptionResult.id,
        title: item?.title,
        price: item?.price,
        description: item?.description,
      });
    }

    return dishOptionResult;
  }

  return (
    <Box>
      <Card>
        <Box p={2}>
          <SelectDishType
            selectedDishType={selectedDishType}
            setSelectedDishType={setSelectedDishType}
          />
        </Box>
      </Card>

      <Divider />

      <Grid container>
        <Grid item xs={12} md={6} p={2}>
          <DishForm
            dish={dish}
            loading={loading}
            onSubmitDishForm={onSubmitDishForm}
          />
          <Divider />
          {dish?.id && <DishAllergens dishId={dish?.id} />}
        </Grid>
        <Grid item xs={12} md={6} p={2}>
          <DishCategoryList
            dish={dish}
            setDish={setDish}
            restaurantID={restaurant?.id}
          />
          {isFood && (
            <>
              <Divider />
              <DishOptionsList
                dish={dish}
                setDish={setDish}
                restaurantID={restaurant?.id}
                onSubmitDishOptionForm={onSubmitDishOptionForm}
              />
            </>
          )}
        </Grid>

        <Button
          block
          size="large"
          type="primary"
          style={{ marginTop: 16 }}
          onClick={() => navigate(-1)}
        >
          Fertig
        </Button>
      </Grid>
    </Box>
  );
}

import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { LatLng } from "use-places-autocomplete";
import { Box, Typography } from "@mui/material";

type Props = {
  location: LatLng & { address: string };
  zoomLevel?: number;
};

export default function MapComponent({ location, zoomLevel = 12 }: Props) {
  /**
   * SETUP GOOGLE MAPS & PLACES AUTO COMPLETE
   */
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_PLACE_API_KEY!,
    libraries: ["places"],
  });

  const currentLocation = React.useMemo(
    () => ({ lat: location.lat, lng: location.lng }),
    [location]
  );

  if (!isLoaded) {
    return null;
  }

  return isLoaded ? (
    <GoogleMap
      center={currentLocation as any}
      zoom={zoomLevel}
      mapContainerStyle={{ height: "50vh", width: "100%", borderRadius: 8 }}
    >
      {currentLocation && <Marker position={currentLocation} />}
    </GoogleMap>
  ) : (
    <Box>
      <Typography>Karte konnte nicht geladen werden</Typography>
    </Box>
  );
}

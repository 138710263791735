import { useEffect, useState } from "react";

import "dayjs/locale/de";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Table } from "antd";
import { isArray } from "lodash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import moment from "moment";

import { Order, OrderStatus } from "../../API";
import { OrderAPI } from "../../api-client";
import { usePersistentStorageValue } from "../../utils/usePersistentStorageValue";
import { useRestaurantContext } from "../../context/restaurant-context";
import renderOrderStatus from "../../utils/renderOrderStatus";

export default function OrderHistory() {
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<
    OrderStatus | undefined
  >();

  const handleChangeOrderStatus = (event: SelectChangeEvent) => {
    setSelectedOrderStatus(event.target.value as OrderStatus | undefined);
  };

  const [daysRange, setDaysRange] = useState<number>(30);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const [orderHistory, setOrderHistory] = usePersistentStorageValue<Order[]>(
    "history-orders",
    []
  );

  // const [orderHistory, setOrderHistory] = useState<Order[]>([]);
  const { restaurant } = useRestaurantContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!restaurant || loading) {
      return;
    }

    fetchData();
  }, [restaurant, daysRange]);

  function fetchData() {
    /**
     * ONLY DISPLAY NEW, COOKING AND READY_FOR_PICKUP ORDERS
     */

    const start = moment()
      .utc()
      .subtract(daysRange, "days")
      .startOf("day")
      .toISOString();

    setLoading(true);
    OrderAPI.fetchOrders({
      filter: {
        and: [
          { createdAt: { gt: start } },
          { orderRestaurantId: { eq: restaurant?.id } },
          {
            or: [
              { status: { eq: OrderStatus.COMPLETED } },
              { status: { eq: OrderStatus.DECLINED_BY_RESTAURANT } },
              // { status: { eq: OrderStatus.PICKED_UP } },
            ],
          },
        ],
      },
    })
      .then((data) => {
        const sortedByCreationDate = data
          .sort(
            // @ts-ignore
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          )
          .reverse();
        setOrderHistory(sortedByCreationDate || data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const tableColumns = [
    {
      title: t("app.order-history.order-id"),
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <strong>{id.substring(0, 4).toUpperCase()}</strong>
      ),
    },
    {
      title: t("app.order-history.created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) =>
        moment(createdAt).format("DD.MM.YYYY - HH:mm") + " Uhr",
    },
    {
      title: t("app.order-history.price"),
      dataIndex: "total",
      key: "total",
      render: (price: number) => `${price} €`,
    },
    {
      title: t("app.order-history.status"),
      dataIndex: "status",
      key: "status",
      render: renderOrderStatus,
    },
  ];

  const onClickedOnRow = (orderItem: Order) => {
    console.log(orderItem);
    navigate(`/order/${orderItem.id}`);
  };

  const filteredOrders = !!selectedOrderStatus
    ? orderHistory.filter((order) => order.status === selectedOrderStatus)
    : orderHistory;

  console.log("ORDER-HISTORY", { orderHistory, filteredOrders });

  return (
    <>
      <Button onClick={fetchData}>Aufträge laden</Button>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
        <Box flexDirection={"column"} marginBottom={2}>
          <Box
            flexDirection={"row"}
            display={"flex"}
            alignItems={"center"}
            justifyItems={"center"}
          >
            <Box marginY={1}>
              <DatePicker
                label="Von"
                value={dayjs().subtract(daysRange, "days")}
                onChange={(newValue) => {
                  const days = dayjs()?.diff(newValue, "days");
                  console.log({ days });

                  setDaysRange(days || 0);
                }}
                disabled={loading}
                disableFuture
                loading={loading}
              />
            </Box>
            <Box marginY={1} marginLeft={1}>
              <DatePicker
                label="Heute"
                value={dayjs()}
                disabled
                disableFuture
              />
            </Box>

            <Box minWidth={300} marginY={1} marginLeft={1}>
              <FormControl fullWidth>
                <InputLabel id="order-status-select-label">
                  Filter nach Auftragsstatus
                </InputLabel>
                <Select
                  labelId="order-status-select-label"
                  id="order-status-select"
                  label="Filter nach Auftragstatus"
                  onChange={handleChangeOrderStatus}
                >
                  <MenuItem value={OrderStatus.COMPLETED}>
                    ABGESCHLOSSEN
                  </MenuItem>
                  <MenuItem value={OrderStatus.DECLINED_BY_RESTAURANT}>
                    ABGELEHNT DURCH RESTAURANT
                  </MenuItem>
                  <MenuItem value={undefined}>ALLE</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>

        <Box>
          <Table
            title={() => (
              <Typography>{filteredOrders.length} Aufträge</Typography>
            )}
            pagination={{ pageSize: 50 }}
            loading={loading}
            dataSource={isArray(filteredOrders) ? filteredOrders : []}
            columns={tableColumns}
            rowKey={"id"}
            onRow={(orderItem) => ({
              onClick: () => onClickedOnRow(orderItem),
            })}
          />
        </Box>
      </LocalizationProvider>
    </>
  );
}

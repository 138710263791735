import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { Button, Card, Descriptions, List, message } from "antd";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/PersonOutline";
import DeliveryIcon from "@mui/icons-material/DeliveryDiningOutlined";
import MoneyIcon from "@mui/icons-material/MoneyOutlined";

import { DeliveryMethod, Order, OrderDish, OrderStatus, User } from "../../API";
import { OrderAPI, OrderDishAPI, UserAPI } from "../../api-client";
import formatPrice from "../../utils/formatPrice";
import OrderDishItem from "./order-dish-item";
import getFees from "../../utils/get-fees";

export default function DetailedOrder() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order>();
  const [customer, setCustomer] = useState<User>();
  const [orderDishes, setOrderDishes] = useState<OrderDish[]>([]);

  const showError = () => {
    messageApi.open({
      type: "error",
      content: "Leider konnte der Auftrag nicht geladen werden...",
    });
  };

  const updateOrderStatus = async (newStatus: OrderStatus) => {
    setLoading(true);
    try {
      await OrderAPI.updateOrderByID({ id: order?.id!, status: newStatus });
      messageApi.open({
        type: "success",
        content: "Auftrag aktualisiert",
      });
    } catch (error) {
      console.error("###### ERROR | updateOrderStatus", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (id?: string) => {
    if (!id) return;

    try {
      setLoading(true);
      /**
       * Fetch Order by ID
       */
      const theOrder = await OrderAPI.fetchOrderByID({ id });

      if (!theOrder) {
        return showError();
      }

      setOrder(theOrder);

      /**
       * Fetching OrderDish
       */
      const theOrderDishes = await OrderDishAPI.queryListByOrderID({
        orderID: theOrder?.id,
      });
      setOrderDishes(theOrderDishes);

      /**
       * Fetching User
       */
      const theUser = await UserAPI.queryItem({ id: theOrder?.userID });
      setCustomer(theUser);

      setTimeout(() => {
        setLoading(false);
      }, 750);
    } catch (e: any) {
      showError();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  useEffect(() => {
    if (id) {
      const subscription = OrderAPI.observeOrders({
        filter: { id: { eq: id } },
      }).subscribe({
        next: ({ value }) => {
          if (value?.data?.onUpdateOrder) {
            setOrder(value.data.onUpdateOrder as Order);
            messageApi.open({
              type: "warning",
              content: "Der Auftrag wurde aktualisiert",
            });
          }
        },
        error: (error) => console.error(error),
      });
      return () => subscription.unsubscribe();
    }
  }, [id]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" minWidth="100%"></Box>

      <Card
        loading={loading}
        title={`${t("app.order-detail.title")} ${id}`}
        actions={[
          <Button
            type="primary"
            disabled={!order?.id}
            onClick={() => fetchData(order?.id)}
          >
            {"Neu laden"}
          </Button>,
        ]}
      >
        <OrderDetails customer={customer} />
        <OrderFees order={order} />
        <CostDetails order={order} />
        <OrderDishList orderDishes={orderDishes} orderStatus={order?.status} />
        {order?.note && <OrderNote note={order?.note} />}
        <OrderActions order={order} updateOrderStatus={updateOrderStatus} />
      </Card>
      {contextHolder}
    </Box>
  );
}

const OrderDetails = ({ customer }: { customer?: User }) => (
  <Accordion key={customer?.id} disableGutters>
    <AccordionSummary>
      <PersonIcon style={{ marginRight: 4, width: 48, marginLeft: -16 }} />
      <strong>Kundeninformationen</strong>
    </AccordionSummary>
    <AccordionDetails>
      <Descriptions column={1}>
        <Descriptions.Item label="Name">{customer?.name}</Descriptions.Item>
        <Descriptions.Item label="Telefon">{customer?.phone}</Descriptions.Item>
        <Descriptions.Item label="Adresse">
          {customer?.address || customer?.addressData?.formattedAddress}
        </Descriptions.Item>
      </Descriptions>
    </AccordionDetails>
  </Accordion>
);

const OrderFees = ({ order }: { order?: Order }) => {
  const fees = getFees(
    order?.createdAt,
    order?.deliveryMethod,
    order?.deliveryProvider
  );
  return (
    <Accordion key={order?.id} disableGutters>
      <AccordionSummary>
        <DeliveryIcon style={{ marginRight: 4, width: 48, marginLeft: -16 }} />
        <strong>Lieferinformationen</strong>
      </AccordionSummary>
      <AccordionDetails>
        <Descriptions column={1}>
          <Descriptions.Item label="Liefermethode">
            <strong>{order?.deliveryMethod}</strong>
          </Descriptions.Item>
          {order?.deliveryMethod !== DeliveryMethod.PICKUP && (
            <Descriptions.Item label="Wer liefert?">
              <strong>{order?.deliveryProvider}</strong>
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Liefergebühr">
            <strong>{formatPrice(fees.deliveryFee)}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Zahlungsgebühr">
            <strong>{fees.customerFee - 100}%</strong>
          </Descriptions.Item>
          <Descriptions.Item label="Restaurantgebühr">
            <strong>{fees.restaurantFee}%</strong>
          </Descriptions.Item>
        </Descriptions>
      </AccordionDetails>
    </Accordion>
  );
};

const CostDetails = ({ order }: { order?: Order }) => (
  <Accordion key={order?.id} disableGutters>
    <AccordionSummary>
      <MoneyIcon style={{ marginRight: 4, width: 48, marginLeft: -16 }} />
      <strong>Kostenübersicht</strong>
    </AccordionSummary>
    <AccordionDetails>
      <Descriptions column={1}>
        <Descriptions.Item label="Kunde bezahlte">
          <strong>{formatPrice(order?.total!)}</strong>
        </Descriptions.Item>
        {order?.voucherAmountOff && (
          <Descriptions.Item label="Ermäßigung">
            <strong>
              {formatPrice(order?.voucherAmountOff / 100)} ({order?.voucherCode}
              )
            </strong>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Kostenträger">
          <strong>Tasty Delivery</strong>
        </Descriptions.Item>
        <Descriptions.Item
          label={"Kostengrundlage (abzüglich Lieferkosten & Zahlungsgebühr)"}
        >
          <strong>
            {formatPrice(
              ((order?.total || 0) +
                (order?.voucherAmountOff ? order?.voucherAmountOff / 100 : 0) -
                getFees(
                  order?.createdAt,
                  order?.deliveryMethod,
                  order?.deliveryProvider
                ).deliveryFee) /
                (getFees(
                  order?.createdAt,
                  order?.deliveryMethod,
                  order?.deliveryProvider
                ).customerFee /
                  100 /
                  100) /
                100
            )}
          </strong>
        </Descriptions.Item>
      </Descriptions>
    </AccordionDetails>
  </Accordion>
);

const OrderDishList = ({
  orderDishes,
  orderStatus,
}: {
  orderDishes: OrderDish[];
  orderStatus?: OrderStatus;
}) => (
  <List
    header={<Typography fontWeight={900}>Bestellte Gerichte</Typography>}
    dataSource={orderDishes}
    renderItem={(d) => (
      <OrderDishItem key={d.id} orderDishItem={d} orderStatus={orderStatus} />
    )}
  />
);

const OrderNote = ({ note }: { note: string }) => (
  <Box m={2} p={2} style={{ backgroundColor: "orange", borderRadius: 4 }}>
    <Typography fontWeight={900} style={{ textDecorationLine: "underline" }}>
      Anmerkung zur Bestellung
    </Typography>
    <Typography>{note}</Typography>
  </Box>
);

const OrderActions = ({
  order,
  updateOrderStatus,
}: {
  order?: Order;
  updateOrderStatus: (newStatus: OrderStatus) => void;
}) => (
  <>
    {order?.status === OrderStatus.NEW && (
      <ActionButtons
        declineText="Bestellung ablehnen"
        acceptText="Bestellung annehmen"
        declineAction={() =>
          updateOrderStatus(OrderStatus.DECLINED_BY_RESTAURANT)
        }
        acceptAction={() => updateOrderStatus(OrderStatus.COOKING)}
      />
    )}
    {order?.status === OrderStatus.COOKING && (
      <ActionButtons
        acceptText="Bereit zur Abholung"
        acceptAction={() => updateOrderStatus(OrderStatus.READY_FOR_PICKUP)}
      />
    )}
  </>
);

const ActionButtons = ({
  declineText,
  acceptText,
  declineAction,
  acceptAction,
}: {
  declineText?: string;
  acceptText: string;
  declineAction?: () => void;
  acceptAction: () => void;
}) => (
  <Box display="flex" justifyContent="space-between">
    {declineText && (
      <Button block danger onClick={declineAction}>
        {declineText}
      </Button>
    )}
    <Button block type="primary" onClick={acceptAction}>
      {acceptText}
    </Button>
  </Box>
);

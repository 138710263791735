import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  List as MUIList,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { message } from "antd";

import { Allergens, Dish } from "../../API";
import { fetchDishByID, updateDishByID } from "../../api-client/dish";
import removeTypename from "../../api-client/helper/remove-typename";

const AddItem = ({
  name,
  onChange,
  onAdd,
}: {
  name: string;
  onChange: (event: any) => void;
  onAdd: () => void;
}) => (
  <Box style={{ display: "flex", flexDirection: "column" }}>
    <TextField
      type="text"
      size="small"
      multiline
      value={name}
      onChange={onChange}
    />
    <Button
      type="button"
      onClick={onAdd}
      startIcon={<Add />}
      style={{ alignSelf: "flex-end" }}
    >
      Hinzufügen
    </Button>
  </Box>
);

type AllergensList = Omit<Allergens, "__typename">[];

export default function DishAllergens({ dishId }: { dishId: string }) {
  const [listData, setListData] = React.useState<AllergensList>([]);
  const [name, setName] = React.useState("");

  useEffect(() => {
    if (!dishId) {
      return;
    }

    fetchDishByID({ id: dishId }).then((_dish) => {
      if (_dish?.allergens) setListData(removeTypename(_dish?.allergens));
    });
  }, [dishId]);

  function handleChange(event: any) {
    setName(event.target.value);
  }

  async function handleAdd() {
    const newList = listData?.concat({
      name,
      id: uuidv4(),
    });

    const newDish = await updateDishByID({
      id: dishId,
      allergens: removeTypename(newList),
    });

    if (newDish.allergens) {
      setListData(newDish.allergens as Allergens[]);
    } else {
      return message.warning(
        "Die Allergene konnten nicht aktuallisiert werden..."
      );
    }

    setName("");
  }

  async function handleRemove(id?: string) {
    const newList = listData?.filter((item) => item?.id !== id);
    const newDish = await updateDishByID({ id: dishId, allergens: newList });

    if (newDish.allergens) {
      setListData(newDish.allergens as Allergens[]);
    } else {
      return message.warning(
        "Die Allergene konnten nicht aktuallisiert werden..."
      );
    }
  }

  return (
    <Box>
      <Typography paddingBottom={2}>Allergene</Typography>
      <AddItem name={name} onChange={handleChange} onAdd={handleAdd} />

      {listData && listData?.length > 0 && (
        <List list={listData} onClickDelete={handleRemove} />
      )}
    </Box>
  );
}

const List = ({
  list,
  onClickDelete,
}: {
  list: AllergensList;
  onClickDelete: (id?: string) => void;
}) => (
  <MUIList dense>
    {list &&
      list.map((item, index) => {
        return (
          <Accordion key={item?.id}>
            <AccordionSummary>Allergene ({index + 1})</AccordionSummary>
            <AccordionDetails>
              <ListItem>
                <ListItemText
                  primary={item?.name}
                  primaryTypographyProps={{ marginRight: 8 }}
                />
              </ListItem>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onClickDelete(item?.id!)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </AccordionDetails>
          </Accordion>
        );
      })}
  </MUIList>
);

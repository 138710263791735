import React, { useEffect, useRef, useState } from "react";

import {
  Chip,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AddTwoTone } from "@mui/icons-material";

import { RestaurantCategory } from "../../API";
import { useRestaurantContext } from "../../context/restaurant-context";
import * as RestaurantCategoryAPI from "../../api-client/restaurant-category";

const RestaurantCategoriesComponent: React.FC = () => {
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>();
  const { restaurant } = useRestaurantContext();
  const [inputValue, setInputValue] = useState<string>();
  const [categories, setCategories] = useState<RestaurantCategory[]>([]);

  useEffect(() => {
    if (!restaurant || !restaurant.id) {
      return;
    }

    RestaurantCategoryAPI.fetchListByRestaurantID({
      filter: { restaurantID: { eq: restaurant.id } },
    }).then((data) => {
      setCategories(data);
    });
  }, [restaurant?.id]);

  const handleClick = () => {};

  const handleAddCategory = async () => {
    const newCategory = await RestaurantCategoryAPI.createItem({
      name: inputValue,
      restaurantID: restaurant?.id,
    });

    setInputValue("");
    setCategories((existingCategories) => [...existingCategories, newCategory]);
  };

  const handleDelete = (chipToDelete: RestaurantCategory) => async () => {
    await RestaurantCategoryAPI.deleteItem({
      id: chipToDelete.id,
      _version: chipToDelete._version,
    });

    setCategories((existingCategories) =>
      existingCategories.filter((category) => category.id !== chipToDelete.id)
    );
  };

  return (
    <>
      <FormControl variant="standard" sx={{ marginBottom: 2 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputLabel>Kategorie</InputLabel>
          <BootstrapInput
            ref={inputRef}
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
          />
          <IconButton
            disabled={!inputValue}
            onClick={handleAddCategory}
            style={{ marginTop: 20, marginLeft: 4 }}
          >
            <AddTwoTone color={"primary"} />
          </IconButton>

          <div style={{ marginTop: 20, marginLeft: 16 }}>
            {categories.map((category) => {
              return (
                <Chip
                  key={category.id}
                  id={category.id}
                  color="secondary"
                  style={{ alignSelf: "center", marginLeft: 4, marginTop: 2 }}
                  // icon={<FoodBankTwoTone />}
                  label={category.name}
                  onClick={handleClick}
                  onDelete={handleDelete(category)}
                />
              );
            })}
          </div>
        </div>
      </FormControl>
    </>
  );
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default RestaurantCategoriesComponent;

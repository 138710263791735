import { useEffect, useState } from "react";
import { Button, Card, Divider, Form, Input, List, message } from "antd";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import { Restaurant } from "../../API";
import { RestaurantAPI } from "../../api-client";
import { useRestaurantContext } from "../../context/restaurant-context";
import MapComponent from "../../components/map";
import PhotoPicker from "../../PhotoPicker";
import Protected from "../../components/protected";
import RestaurantCategoriesComponent from "../../components/restaurant-categories";
import uploadImage from "../../utils/uploadImage";

export type ImagePickData = {
  file: File;
  name: string;
  size: number;
  type: string;
};

type Coordinates = {
  lat: number;
  lng: number;
} | null;

export default function Settings() {
  const [logoPreviewImage, setLogoPreviewImage] = useState<string>();
  const [logoData, setLogoData] = useState<ImagePickData>();
  const [teaserPreviewImage, setTeaserPreviewImage] = useState<string>();
  const [teaserData, setTeaserData] = useState<ImagePickData>();

  const { t } = useTranslation();
  const { restaurant, setRestaurant, sub } = useRestaurantContext();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    ready,
    value: address,
    setValue: setAddress,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<Restaurant["name"]>("");
  const [phone, setPhone] = useState<Restaurant["phone"]>("");
  const [email, setEmail] = useState<Restaurant["email"]>("");
  const [emailPrinter, setEmailPrinter] =
    useState<Restaurant["emailPrinter"]>("");

  const [coordinates, setCoordinates] = useState<Coordinates>(null);

  useEffect(() => {
    if (restaurant) {
      setName(restaurant.name);
      setPhone(restaurant.phone);
      setEmail(restaurant.email);
      setEmailPrinter(restaurant.emailPrinter);
      setAddress(restaurant.address, false);
      setCoordinates({ lat: restaurant.lat, lng: restaurant.lng });
    }
  }, [restaurant, setAddress]);

  const selectLocation = async (address: string) => {
    setAddress(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });

    const { lat, lng } = getLatLng(results[0]);
    setCoordinates({ lat, lng });
  };

  const onFinish = async () => {
    if (!restaurant) {
      await createNewRestaurant();
    } else {
      await updateNewRestaurant(restaurant);
    }
  };

  const createNewRestaurant = async () => {
    setLoading(true);

    try {
      if (!address || !coordinates) {
        return alert("Missing address or coordinates");
      }

      let logoKey = null;
      let teaserKey = null;
      let image: { logoKey: string | null; teaserKey: string | null } = {
        logoKey: null,
        teaserKey: null,
      };

      if (logoData) {
        logoKey = await uploadImage(logoData.name, logoData.file, null, 240);
        image.logoKey = logoKey;
      }

      if (teaserData) {
        teaserKey = await uploadImage(
          teaserData.name,
          teaserData.file,
          null,
          640
        );
        image.teaserKey = teaserKey;
      }

      const newRestaurant = await RestaurantAPI.createItem({
        name: name,
        address: address,
        lat: coordinates.lat,
        lng: coordinates.lng,
        minDeliveryTime: 15,
        maxDeliveryTime: 45,
        adminSub: sub,
        image,
      });

      setRestaurant(newRestaurant);
      message.success(t("app.settings.messages.create-success"));
    } catch (error) {
      console.error("ERROR createNewRestaurant", error);
    } finally {
      setTimeout(() => setLoading(false), 500);
    }
  };

  const updateNewRestaurant = async (currentRestaurant: Restaurant) => {
    setLoading(true);

    try {
      let logoKey = null;
      let teaserKey = null;
      let image: { logoKey: string | null; teaserKey: string | null } = {
        logoKey: null,
        teaserKey: null,
      };

      if (logoData) {
        logoKey = await uploadImage(logoData.name, logoData.file, null, 240);
        image.logoKey = logoKey;
      }
      if (teaserData) {
        teaserKey = await uploadImage(
          teaserData.name,
          teaserData.file,
          null,
          640
        );
        image.teaserKey = teaserKey;
      }

      const origin = await RestaurantAPI.fetchRestaurantByAdminID({
        filter: { adminSub: { eq: currentRestaurant.adminSub } },
      });

      if (!origin) {
        return message.error("Restaurant konnte nicht aktualisiert werden ...");
      }

      const updatedRestaurant = await RestaurantAPI.updateRestaurantByID({
        id: origin.id,
        name,
        address,
        lat: coordinates?.lat,
        lng: coordinates?.lng,
        phone,
        email,
        emailPrinter,
        image: {
          logoKey: image.logoKey || origin.image?.logoKey || null,
          teaserKey: image.teaserKey || origin.image?.teaserKey || null,
        },
      });

      setRestaurant(updatedRestaurant);
      message.success(t("app.settings.messages.update-success"));
    } catch (error) {
      console.error("ERROR updateNewRestaurant", error);
    } finally {
      setTimeout(() => setLoading(false), 500);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("onFinishFailed", errorInfo);
  };

  const imageStyle = {
    height: 150,
    width: 150,
  };

  return (
    <Protected>
      <Box>
        <Divider />
        {/*
        <Box
          flexDirection={"column"}
          display="flex"
          justifyContent={"space-between"}
        >
          <RestaurantStatusSwitch />
          <Divider />
          <LoggingSwitch />
        </Box>
        <Divider /> */}
        <Form
          layout={"vertical"}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          disabled={loading}
        >
          <Box
            sx={{
              flexDirection: !matches ? "column" : "row",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Form.Item
              style={{ marginRight: "1rem" }}
              label={t("app.settings.image-upload.label")}
              rules={[{ required: true }]}
              required
            >
              <PhotoPicker
                s3Image={restaurant?.image?.logoKey}
                title={t("app.settings.file-upload.label")}
                preview
                previewSrc={logoPreviewImage}
                onLoad={(url) => {
                  console.log("onLoad", url);
                  setLogoPreviewImage(url as string);
                }}
                onPick={(data) => {
                  console.log("onPick", data);
                  setLogoData(data);
                }}
                containerStyle={{
                  display: "flex",
                  flexDirection: "column",
                }}
                previewStyle={imageStyle}
                theme={
                  {
                    button: {
                      marginTop: 8,
                      width: imageStyle.width,
                    },
                  } as any
                }
              />
            </Form.Item>

            <Form.Item
              label={"Vorschau-Bild"}
              rules={[{ required: true }]}
              required
            >
              <PhotoPicker
                s3Image={restaurant?.image?.teaserKey}
                title={t("app.settings.file-upload.label")}
                preview
                previewSrc={teaserPreviewImage}
                onLoad={(url) => {
                  console.log("onLoad", url);
                  setTeaserPreviewImage(url as string);
                }}
                onPick={(data) => {
                  console.log("onPick", data);
                  setTeaserData(data);
                }}
                containerStyle={{
                  display: "flex",
                  flexDirection: "column",
                }}
                previewStyle={imageStyle}
                theme={
                  {
                    button: {
                      marginTop: 8,
                      width: imageStyle.width,
                    },
                  } as any
                }
              />
            </Form.Item>
          </Box>

          <Form.Item
            label={t("app.settings.label.name")}
            rules={[{ required: true }]}
            required
          >
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t("app.settings.placeholder.name") || ""}
            />
          </Form.Item>

          <Form.Item
            label={t("app.settings.label.address")}
            rules={[{ required: true }]}
            required
          >
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={t("app.settings.placeholder.address") || ""}
            />
          </Form.Item>

          <Form.Item
            label={"Support-Telefon-Nummer"}
            rules={[{ required: true }]}
            required
          >
            <Input
              value={phone || ""}
              type="tel"
              onChange={(e) => setPhone(e.target.value)}
              placeholder={"Telefonnummer für Kundenanfragen angeben"}
            />
          </Form.Item>

          <Form.Item
            label={"Support-Email"}
            rules={[{ required: true }]}
            required
          >
            <Input
              value={email || ""}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder={"Support-E-Mail Adresse für Kundenanfragen angeben"}
            />
          </Form.Item>

          <Form.Item
            label={
              <Box flexDirection={"column"} display={"flex"}>
                <Typography variant="inherit">
                  E-Mail für die Auftragsbestätigung
                </Typography>
                <Typography variant="caption" fontWeight="700" color={"orange"}>
                  Wenn eine Email angegeben ist werden eingehende Aufträge
                  automatisch akzeptiert und nach erfolgreicher Konfiguration
                  vom Tasty Developer Team ausgedruckt
                </Typography>
              </Box>
            }
            rules={[{ required: false }]}
            required
          >
            <Input
              value={emailPrinter || ""}
              type="email"
              onChange={(e) => setEmailPrinter(e.target.value)}
              placeholder={"E-Mail für die Auftragsbestätigung festlegen"}
            />
          </Form.Item>

          <RestaurantCategoriesComponent />

          {status === "OK" && (
            <Card>
              <List
                dataSource={data}
                renderItem={({ place_id, description }) => {
                  return (
                    <Button
                      key={place_id}
                      style={{ display: "flex" }}
                      type={"text"}
                      onClick={() => selectLocation(description)}
                    >
                      {description}
                    </Button>
                  );
                }}
              />
            </Card>
          )}

          {restaurant && coordinates && (
            <div style={{ marginBottom: 16 }}>
              <MapComponent
                location={{
                  address: restaurant.address,
                  lat: coordinates.lat,
                  lng: coordinates.lng,
                }}
              />
            </div>
          )}

          <Form.Item>
            <Button type={"primary"} htmlType={"submit"} loading={loading}>
              {t("app.settings.button-save")}
            </Button>
          </Form.Item>
        </Form>
        <span>
          {coordinates?.lat} - {coordinates?.lng}
        </span>
      </Box>
    </Protected>
  );
}

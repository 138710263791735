import React, { useEffect, useState } from "react";
import { Button, Popconfirm } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import { Delete, ExpandMore } from "@mui/icons-material";

import { Dish, DishCategory } from "../../API";
import { DishAPI, DishCategoryAPI } from "../../api-client";
import { useRestaurantContext } from "../../context/restaurant-context";
import Protected from "../../components/protected";

import "./styles.css";
import removeTypename from "../../api-client/helper/remove-typename";

export default function RestaurantMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dishes, setDishes] = useState<Dish[]>([]);
  const [dishCategories, setDishCategories] = useState<DishCategory[]>([]);
  const { restaurant } = useRestaurantContext();

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    fetchData(restaurant.id);
  }, [restaurant, restaurant?.id]);

  const fetchData = async (restaurantID: string) => {
    const variables = {
      filter: { restaurantID: { eq: restaurantID } },
    };

    const _dishes = await DishAPI.queryList(variables);
    setDishes(_dishes);

    const _dishCategories = await DishCategoryAPI.queryList(variables);
    setDishCategories(_dishCategories);
  };

  const groupedByCategory = _.groupBy(dishes, (dish) => dish.dishCategoryId);
  const sectionList = Object.entries(groupedByCategory)
    .map(([key, val]) => {
      const category = dishCategories.find((dc) => dc.id === key);
      return { title: category?.name || "Sonstige", data: val };
    })
    .sort((a, b) => {
      var exceptions = ["Sonstige"], // put sonstige to last position
        indexA,
        indexB;
      indexA = exceptions.indexOf(a.title);
      indexB = exceptions.indexOf(b.title);
      if (indexA === -1 && indexB === -1) {
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase()); // regular case
      }
      return indexA - indexB; // index will be -1 (doesn't occur), 0 or 1
    });

  const onClickEdit = async (dish: Dish) => {
    navigate(`/menu/edit/${dish.id}`);
  };

  const onConfirmDeleteDish = async (dish: Dish) => {
    // first we need to remove the category reference from the dish
    // if we dont do this, we will delete the category as well
    const updatedDish = await DishAPI.updateDishByID({
      id: dish.id,
      dishCategoryId: null,
    });
    await DishAPI.deleteItem({
      id: updatedDish.id,
      _version: updatedDish._version,
    });
    setDishes(dishes.filter((d) => d.id !== dish.id));
  };

  const handleSoldOutChange = async (checked: boolean, dish: Dish) => {
    await DishAPI.updateDishByID({
      id: dish.id,
      soldOut: checked,
    });
    fetchData(restaurant!.id);
  };

  return (
    <Protected>
      <Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Speisekarte</Typography>
          <Link to={"create"}>
            <Button type={"primary"}>
              {t("app.menu-item.button-new-item")}
            </Button>
          </Link>
        </Box>
        {sectionList.map((section, index) => {
          return (
            <List key={index}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">{section.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {section.data.map((item) => {
                    return (
                      <div key={item.id}>
                        <ListItem
                          onClick={() => onClickEdit(item)}
                          disablePadding
                        >
                          <ListItemButton>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Typography marginRight={1}>
                                      {item.name}
                                    </Typography>
                                    <Typography fontWeight={"light"}>
                                      ({item.price} €)
                                    </Typography>
                                  </Box>
                                </React.Fragment>
                              }
                              secondary={item.description}
                            />
                          </ListItemButton>

                          <ListItemSecondaryAction>
                            <FormControlLabel
                              disabled={!restaurant?.id}
                              control={
                                <Switch
                                  value={item.soldOut}
                                  defaultChecked={!!item.soldOut}
                                />
                              }
                              onChange={(e, checked) =>
                                handleSoldOutChange(checked, item)
                              }
                              label="Ausverkauft"
                            />

                            <Popconfirm
                              placement={"topLeft"}
                              title={t("app.menu-item.pop-alert.title")}
                              onConfirm={() => onConfirmDeleteDish(item)}
                              okText={t("app.menu-item.pop-alert.yes")}
                              cancelText={t("app.menu-item.pop-alert.no")}
                            >
                              <IconButton edge="end" aria-label="delete">
                                <Delete color={"error"} />
                              </IconButton>
                            </Popconfirm>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="middle" component="li" />
                      </div>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </List>
          );
        })}
      </Box>
    </Protected>
  );
}

import { Storage } from "aws-amplify";
import {
  createCanvas,
  imgToBlobAsync,
  loadImgAsync,
  readFileAsync,
} from "./resize-image";

export default async function uploadImage(
  filename?: string,
  file?: File,
  oldImageKey?: string | null | undefined,
  maxImageSize?: number
): Promise<string | null> {
  if (file === null || !filename) return null;

  try {
    if (!!oldImageKey) {
      await Storage.remove(oldImageKey);
    }

    // Read the image
    const imgSrc = await readFileAsync(file!);
    const img = await loadImgAsync(imgSrc as string); // From the image and a canvas (for the resize),
    // generate a blob to be uploaded
    console.log({ maxImageSize });
    const canvas = createCanvas(img, maxImageSize ? maxImageSize : 1000);
    const imgBlob = await imgToBlobAsync(img, canvas);

    // Create a file from the blob
    const fileMain = new File([imgBlob], filename, {
      type: "image/*",
      lastModified: Date.now(),
    }) as unknown as string;

    console.log({ fileMain });

    const result = await Storage.put(filename, fileMain, {
      level: "public",
      contentType: "image/*",
    });
    console.log("STORAGE PUT SUCCESS: ", result.key);
    return result.key;
  } catch (err) {
    console.log("STORAGE PUT ERROR: ", err);
    return null;
  }
}

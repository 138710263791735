import React from "react";
import { Card, Typography } from "antd";

export default function NotFound() {
  return (
    <Card title={"404"}>
      <Typography.Title>404</Typography.Title>
    </Card>
  );
}

import { useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { TextField } from "@aws-amplify/ui-react";
import { Box, Typography } from "@mui/material";

import { BillingDetails, BillingDetailsInput } from "../../API";
import { useRestaurantContext } from "../../context/restaurant-context";
import removeTypename from "../../api-client/helper/remove-typename";

export default function BillingDetailsForm() {
  const {
    restaurant,
    updateRestaurantBillingDetails,
    fetchRestaurantByAdminSub,
  } = useRestaurantContext();

  useEffect(() => {
    fetchRestaurantByAdminSub();
  }, []);

  const changeHandler = (billingDetails: BillingDetailsInput) => {
    const mergedBillingAddress = Object.assign(
      restaurant?.billingDetails || {},
      billingDetails
    );
    updateRestaurantBillingDetails(mergedBillingAddress as BillingDetails);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  return (
    <Box marginY={0} maxWidth={360}>
      <Typography paddingBottom={2} variant="subtitle1">
        Rechnungsanschrift
      </Typography>
      <TextField
        size="small"
        paddingBlock={2}
        label="Name"
        defaultValue={
          restaurant?.billingDetails
            ?.name as React.AllHTMLAttributes<"input">["value"]
        }
        onChange={(event) =>
          debouncedChangeHandler({ name: event.target.value })
        }
      />

      <TextField
        size="small"
        paddingBlock={2}
        label="Strasse"
        defaultValue={
          restaurant?.billingDetails?.address
            ?.line1 as React.AllHTMLAttributes<"input">["value"]
        }
        onChange={(event) =>
          debouncedChangeHandler({
            address: removeTypename({
              ...restaurant?.billingDetails?.address,
              line1: event.target.value,
            }),
          })
        }
      />
      <Box
        flexDirection={"row"}
        display="flex"
        justifyContent={"space-between"}
      >
        <TextField
          size="small"
          paddingBlock={2}
          flex={0.325}
          label="Postleitzahl"
          defaultValue={
            restaurant?.billingDetails?.address
              ?.postal_code as React.AllHTMLAttributes<"input">["value"]
          }
          onChange={(event) =>
            debouncedChangeHandler({
              address: removeTypename({
                ...restaurant?.billingDetails?.address,
                postal_code: event.target.value,
              }),
            })
          }
        />
        <TextField
          size="small"
          paddingBlock={2}
          flex={0.625}
          label="Stadt"
          defaultValue={
            restaurant?.billingDetails?.address
              ?.city as React.AllHTMLAttributes<"input">["value"]
          }
          onChange={(event) =>
            debouncedChangeHandler({
              address: removeTypename({
                ...restaurant?.billingDetails?.address,
                city: event.target.value,
              }),
            })
          }
        />
      </Box>

      <TextField
        size="small"
        paddingBlock={2}
        label="Rechnungs E-Mail"
        defaultValue={
          restaurant?.billingDetails
            ?.email as React.AllHTMLAttributes<"input">["value"]
        }
        onChange={(event) =>
          debouncedChangeHandler({ email: event.target.value })
        }
      />
    </Box>
  );
}

import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import {
  BusinessHourRestaurant,
  CreateBusinessHourRestaurantInput,
  CreateBusinessHourRestaurantMutation,
  DeleteBusinessHourRestaurantInput,
  DeleteBusinessHourRestaurantMutation,
  GetBusinessHourRestaurantQuery,
  GetBusinessHourRestaurantQueryVariables,
  ListBusinessHourRestaurantsQuery,
  ListBusinessHourRestaurantsQueryVariables,
  OnUpdateBusinessHourRestaurantSubscription,
  OnUpdateBusinessHourRestaurantSubscriptionVariables,
  UpdateBusinessHourRestaurantInput,
  UpdateBusinessHourRestaurantMutation,
} from "../API";
import {
  getBusinessHourRestaurant,
  listBusinessHourRestaurants,
} from "../graphql/queries";
import { onUpdateBusinessHourRestaurant } from "../graphql/subscriptions";
import {
  createBusinessHourRestaurant,
  deleteBusinessHourRestaurant,
  updateBusinessHourRestaurant,
} from "../graphql/mutations";
import removeTypename from "./helper/remove-typename";
import addBaseFilterToVariables from "./helper/filter-deleted";

export async function queryItem(
  variables: GetBusinessHourRestaurantQueryVariables
): Promise<GetBusinessHourRestaurantQuery["getBusinessHourRestaurant"]> {
  try {
    const payload = await API.graphql<
      GraphQLQuery<GetBusinessHourRestaurantQuery>
    >(graphqlOperation(getBusinessHourRestaurant, variables ? variables : {}));
    return payload.data?.getBusinessHourRestaurant;
  } catch (error) {
    console.error("Error querying item:", error);
    return null; // Rethrow the error to be handled by the caller if needed.
  }
}

export async function queryList(
  variables: ListBusinessHourRestaurantsQueryVariables = {}
): Promise<BusinessHourRestaurant[]> {
  try {
    const payload = await API.graphql<
      GraphQLQuery<ListBusinessHourRestaurantsQuery>
    >(
      graphqlOperation(
        listBusinessHourRestaurants,
        variables ? addBaseFilterToVariables(variables) : {}
      )
    );

    return payload.data?.listBusinessHourRestaurants
      ?.items as BusinessHourRestaurant[];
  } catch (error) {
    console.error("Error querying list:", error);
    return [];
  }
}

export async function createItem(input: CreateBusinessHourRestaurantInput) {
  try {
    const payload = await API.graphql<
      GraphQLQuery<CreateBusinessHourRestaurantMutation>
    >({
      query: createBusinessHourRestaurant,
      variables: { input: removeTypename(input) },
    });
    return payload.data?.createBusinessHourRestaurant as BusinessHourRestaurant;
  } catch (error) {
    console.error("Error creating item:", error);
    return null;
  }
}

export async function updateItem(input: UpdateBusinessHourRestaurantInput) {
  try {
    const origin = await queryItem({ id: input.id });
    const inputWithVersion = {
      ...input,
      _version: origin?._version,
    };

    const payload = await API.graphql<
      GraphQLQuery<UpdateBusinessHourRestaurantMutation>
    >({
      query: updateBusinessHourRestaurant,
      variables: { input: removeTypename(inputWithVersion) },
    });
    return payload.data?.updateBusinessHourRestaurant as BusinessHourRestaurant;
  } catch (error) {
    console.error("Error updating item:", error);
    return null;
  }
}

export async function deleteItem(input: DeleteBusinessHourRestaurantInput) {
  try {
    await API.graphql<GraphQLQuery<DeleteBusinessHourRestaurantMutation>>({
      query: deleteBusinessHourRestaurant,
      variables: { input: removeTypename(input) },
    });
  } catch (error) {
    console.error("Error deleting item:", error);
    return null;
  }
}

export function updateSubscription(
  variables?: OnUpdateBusinessHourRestaurantSubscriptionVariables
) {
  return API.graphql<
    GraphQLSubscription<OnUpdateBusinessHourRestaurantSubscription>
  >({
    query: onUpdateBusinessHourRestaurant,
    variables,
  });
}

import * as React from "react";

const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;

const useDisplayMessage = () => {
  const [_message, setMessage] = React.useState<string | null>(null);
  const [type, setType] = React.useState<"success" | "error" | "info">("info"); // Type can be 'success', 'error', etc.
  const [isVisible, setIsVisible] = React.useState(false);

  // Function to show the _message
  const show = React.useCallback(
    (msg: string | null, msgType = "info", duration = 3000) => {
      setMessage(msg);
      setType(msgType as "success" | "error" | "info");
      setIsVisible(true);

      // Hide the _message after the specified duration
      setTimeout(() => {
        setIsVisible(false);
      }, duration);
    },
    []
  );

  // Function to close the _message
  const close = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  return { show, close, message: _message, type, isVisible };
};

const useNavigatorOnLine = () => {
  const [status, setStatus] = React.useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  React.useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  return status;
};

export { getOnLineStatus, useDisplayMessage, useNavigatorOnLine };

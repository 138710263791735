import * as React from "react";
import {
  useDisplayMessage,
  useNavigatorOnLine,
} from "../../hooks/useNavigatorOnLine";
import { Box, Typography } from "@mui/material";

const NetworkStatusIndicator = () => {
  const { show, close, type, message, isVisible } = useDisplayMessage();
  const isOnline = useNavigatorOnLine();
  const firstUpdate = React.useRef(true);

  React.useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    isOnline
      ? show("You are online!", "success")
      : show("You are currently offline", "error");
  }, [close, show, isOnline]);

  console.log("NetworkStatusIndicator", {
    isOnline,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <Box position={"absolute"}>
      <Typography
        color={type === "error" ? "red" : type === "info" ? "blue" : "green"}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default NetworkStatusIndicator;

import { OrderStatus } from "../models";
import { Tag } from "antd";
import _ from "lodash";

import i18n from "../i18n";

export const statusToColor = {
  /**
   * Order statuses
   */
  [OrderStatus.ACCEPTED]: "purple",
  [OrderStatus.NEW]: "blue",
  [OrderStatus.COOKING]: "orange",
  [OrderStatus.READY_FOR_PICKUP]: "red",
  /**
   * History statuses
   */
  [OrderStatus.PICKED_UP]: "blue",
  [OrderStatus.COMPLETED]: "green",
  [OrderStatus.DECLINED_BY_RESTAURANT]: "red",
};

const renderOrderStatus = (orderStatus: OrderStatus | string) => {
  return (
    <Tag
      color={_.get(statusToColor, orderStatus, "black")}
      style={{ fontSize: 16, padding: 4 }}
    >
      {i18n.t(`app.order-status.${orderStatus}`)}
    </Tag>
  );
};

export default renderOrderStatus;

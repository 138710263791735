import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";

import {
  DishCategory,
  GetDishCategoryQuery,
  GetDishCategoryQueryVariables,
  OnUpdateDishCategorySubscription,
  OnUpdateDishCategorySubscriptionVariables,
  UpdateDishCategoryInput,
  UpdateDishCategoryMutation,
  ListDishCategoriesQuery,
  ListDishCategoriesQueryVariables,
  CreateDishCategoryInput,
  CreateDishCategoryMutation,
  DeleteDishCategoryInput,
  DeleteDishCategoryMutation,
} from "../API";

import { getDishCategory, listDishCategories } from "../graphql/queries";
import { onUpdateDishCategory } from "../graphql/subscriptions";
import {
  createDishCategory,
  deleteDishCategory,
  updateDishCategory,
} from "../graphql/mutations";
import removeTypename from "./helper/remove-typename";
import addBaseFilterToVariables from "./helper/filter-deleted";

export async function queryItem(variables: GetDishCategoryQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<GetDishCategoryQuery>>(
    graphqlOperation(getDishCategory, variables ? variables : {})
  );

  return payload.data?.getDishCategory as DishCategory;
}
export async function queryList(variables: ListDishCategoriesQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<ListDishCategoriesQuery>>(
    graphqlOperation(
      listDishCategories,
      variables ? addBaseFilterToVariables(variables) : {}
    )
  );

  return payload.data?.listDishCategories?.items as DishCategory[];
}

export async function createItem(input: CreateDishCategoryInput) {
  const payload = await API.graphql<GraphQLQuery<CreateDishCategoryMutation>>({
    query: createDishCategory,
    variables: { input: removeTypename(input) },
  });
  return payload.data?.createDishCategory as DishCategory;
}

export async function deleteItem(input: DeleteDishCategoryInput) {
  await API.graphql<GraphQLQuery<DeleteDishCategoryMutation>>({
    query: deleteDishCategory,
    variables: { input },
  });
}

export async function updateDishCategoryByID(input: UpdateDishCategoryInput) {
  const origin = await queryItem({ id: input.id });
  const inputWithVersion = {
    ...input,
    _version: origin?._version,
  };

  const payload = await API.graphql<GraphQLQuery<UpdateDishCategoryMutation>>({
    query: updateDishCategory,
    variables: { input: removeTypename(inputWithVersion) },
  });
  return payload.data?.updateDishCategory as DishCategory;
}

// Subscribe to update of categories
export const observeDishCategories = (
  variables: OnUpdateDishCategorySubscriptionVariables
) =>
  API.graphql<GraphQLSubscription<OnUpdateDishCategorySubscription>>({
    query: onUpdateDishCategory,
    variables: addBaseFilterToVariables(variables),
  });

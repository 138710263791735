export default function formatPrice(
  price: number | string,
  locale: string = "de-DE",
  currency: string = "EUR"
): string {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(Number(price));
}

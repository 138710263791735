// Create a context
import React, { createContext, ReactElement } from "react";
import { usePersistentStorageValue } from "../utils/usePersistentStorageValue";

export type DesktopNotification = "update" | "insert" | "all" | "none";

export interface ISettingsContext {
  notification: DesktopNotification;
  setNotification: (v: DesktopNotification) => void;
}

const SettingsContext: React.Context<{}> = createContext<ISettingsContext | {}>(
  {}
);

const SettingsContextProvider = ({ children }: { children: ReactElement }) => {
  const [notification, setNotification] =
    usePersistentStorageValue<DesktopNotification>(
      "desktop-notifications",
      "all"
    );

  return (
    <SettingsContext.Provider
      value={{
        notification,
        setNotification,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = (): ISettingsContext =>
  React.useContext(SettingsContext) as ISettingsContext;

export { SettingsContext, SettingsContextProvider };

import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { DishType } from "../../API";

export default function SelectDishType({
  selectedDishType,
  setSelectedDishType,
}: {
  selectedDishType: DishType;
  setSelectedDishType: (dishType: DishType) => void;
}) {
  return (
    <FormControl>
      <FormLabel id="radio-buttons-group-label-dish-type">
        Essen oder Getränk
      </FormLabel>
      <RadioGroup
        //row
        aria-labelledby="radio-buttons-group-label-dish-type"
        name="radio-buttons-group-dish-type"
        defaultValue={DishType.FOOD}
        value={selectedDishType}
        onChange={(event) =>
          setSelectedDishType(event.target.value as DishType)
        }
      >
        <FormControlLabel
          value={DishType.FOOD}
          control={<Radio />}
          label="Essen"
        />
        <FormControlLabel
          value={DishType.DRINK_NON_ALCOHOLIC}
          control={<Radio />}
          label="Softdrink"
        />
        <FormControlLabel
          value={DishType.DRINK_ALCOHOLIC}
          control={<Radio />}
          label="Alkoholisches Getränke"
        />
        {/*
        <FormControlLabel
          value="disabled"
          disabled
          control={<Radio />}
          label="other"
        />
        */}
      </RadioGroup>
    </FormControl>
  );
}

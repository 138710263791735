import React from "react";
import { Box, Typography } from "@mui/material";

// @ts-ignore
import { ReactInternetSpeedMeter } from "react-internet-meter";
import "react-internet-meter/dist/index.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useRestaurantContext } from "../../context/restaurant-context";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default function InternetSpeedMeter() {
  const context = useRestaurantContext();

  const [checkSpeed, SetCheckSpeed] = React.useState(
    "Internetgeschwindigkeit wird überprüft, dieser Prozess läuft im Hintergrund."
  );

  return (
    <Box margin={2}>
      <ReactInternetSpeedMeter
        txtSubHeading="Internetverbindung ist zu langsam..."
        outputType="empty" // "alert"/"modal"/"empty"
        customClassName={null}
        pingInterval={30000} // milliseconds
        txtMainHeading="Whopps..."
        thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
        threshold={500}
        imageUrl="https://static.wixstatic.com/media/77991f_192ff896105241bf9a3fa0337b296617~mv2.jpg/v1/fill/w_1732,h_1102,al_c,q_85,enc_auto/77991f_192ff896105241bf9a3fa0337b296617~mv2.jpg"
        downloadSize="170000" //bytes
        // @ts-ignore
        callbackFunctionOnNetworkDown={(data) => {
          console.log(`Internet speed : ${data}`);

          logEvent(analytics, "internet_speed_down", {
            speed: data,
            restaurant_id: context.restaurant?.id,
            restaurant_name: context.restaurant?.name,
          });
        }}
        // @ts-ignore
        callbackFunctionOnNetworkTest={(data) => {
          logEvent(analytics, "internet_speed", {
            speed: data,
            restaurant_id: context.restaurant?.id,
            restaurant_name: context.restaurant?.name,
          });

          SetCheckSpeed(data);
        }}
      />
      <Box>
        <Typography variant="caption">{checkSpeed} MB/s</Typography>
      </Box>
    </Box>
  );
}

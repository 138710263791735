import LZString from "lz-string";

interface PersistentStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: any): void;
}

class LocalStorage implements PersistentStorage {
  getItem(key: string) {
    const item = localStorage.getItem(key);

    if (item === null) return undefined;

    if (item === "null") return null;
    if (item === "undefined") return undefined;

    try {
      const decomprimiert = JSON.parse(LZString.decompressFromUTF16(item)); // Dekomprimieren des gespeicherten Werts
      console.log("LocalStorage", { decomprimiert });
      return decomprimiert;
    } catch {}

    console.log("LocalStorage", { item });
    return item;
  }

  setItem(key: string, value: any) {
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      const compressedValue = LZString.compressToUTF16(JSON.stringify(value)); // Komprimieren des Werts vor dem Speichern
      localStorage.setItem(key, compressedValue);
    }
  }
}

class MockStorage implements PersistentStorage {
  getItem() {
    return null;
  }
  setItem() {}
}

export const persistentStorage = window?.localStorage
  ? new LocalStorage()
  : new MockStorage();

import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";

import {
  CreateDishOptionInput,
  CreateDishOptionMutation,
  DeleteDishOptionInput,
  DeleteDishOptionMutation,
  DishOption,
  GetDishOptionQuery,
  GetDishOptionQueryVariables,
  ListDishOptionsQuery,
  ListDishOptionsQueryVariables,
  OnUpdateDishOptionSubscription,
  OnUpdateDishOptionSubscriptionVariables,
  UpdateDishOptionInput,
  UpdateDishOptionMutation,
} from "../API";

import { getDishOption, listDishOptions } from "../graphql/queries";
import { onUpdateDishOption } from "../graphql/subscriptions";
import {
  createDishOption,
  deleteDishOption,
  updateDishOption,
} from "../graphql/mutations";
import removeTypename from "./helper/remove-typename";
import addBaseFilterToVariables from "./helper/filter-deleted";

export async function fetchDishOptionByID(
  variables: GetDishOptionQueryVariables
) {
  const payload = await API.graphql<GraphQLQuery<GetDishOptionQuery>>(
    graphqlOperation(getDishOption, variables ? variables : {})
  );

  return payload.data?.getDishOption as DishOption;
}

export async function queryList(variables: ListDishOptionsQueryVariables) {
  const payload = await API.graphql<GraphQLQuery<ListDishOptionsQuery>>(
    graphqlOperation(
      listDishOptions,
      variables ? addBaseFilterToVariables(variables) : {}
    )
  );

  return payload.data?.listDishOptions?.items as DishOption[];
}

export async function createItem(input: CreateDishOptionInput) {
  const payload = await API.graphql<GraphQLQuery<CreateDishOptionMutation>>({
    query: createDishOption,
    variables: { input: removeTypename(input) },
  });
  return payload.data?.createDishOption as DishOption;
}

export async function updateDishOptionByID(input: UpdateDishOptionInput) {
  const origin = await fetchDishOptionByID({ id: input.id });
  const inputWithVersion = {
    ...input,
    _version: origin?._version,
  };

  const payload = await API.graphql<GraphQLQuery<UpdateDishOptionMutation>>({
    query: updateDishOption,
    variables: { input: removeTypename(inputWithVersion) },
  });
  return payload.data?.updateDishOption as DishOption;
}

export async function deleteItem(input: DeleteDishOptionInput) {
  await API.graphql<GraphQLQuery<DeleteDishOptionMutation>>({
    query: deleteDishOption,
    variables: { input },
  });
}

// Subscribe to update of dishes
export const observeDishOptiones = (
  variables: OnUpdateDishOptionSubscriptionVariables
) =>
  API.graphql<GraphQLSubscription<OnUpdateDishOptionSubscription>>({
    query: onUpdateDishOption,
    variables,
  });

import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  List,
  message,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import {
  DeleteTwoTone,
  FileAddFilled,
  ReloadOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { t } from "i18next";
import Box from "@mui/material/Box";
import MUIButton from "@mui/material/Button";

import { Dish, DishOption, DishOptionItem } from "../../../API";
import { IDishOptionForm } from "../dish-option-form";
import { useRestaurantContext } from "../../../context/restaurant-context";
import CreateDishOptionModal from "../create-dish-option-modal";
import CreateDishOptionItemModal from "../create-dish-option-item-modal";
import { DishOptionAPI, DishOptionItemAPI } from "../../../api-client";

type Props = {
  dish?: Dish;
  setDish: (d: Dish) => void;
  restaurantID?: string;
  onSubmitDishOptionForm: (data: IDishOptionForm) => void;
};

export default function DishOptionsList(props: Props) {
  const { restaurant } = useRestaurantContext();

  const [createOptionModal, setCreateOptionModal] = useState<boolean>(false);
  const [createOptionItemModal, setCreateOptionItemModal] =
    useState<boolean>(false);
  const [dishOptionID, setDishOptionID] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [listData, setListData] = useState<DishOption[]>([]);

  /**
   * Observe Model Order of type UPDATE & matching to order id
   */
  React.useEffect(() => {
    if (!props.dish?.id) {
      return;
    }
    fetchDishOption();
  }, [props.dish]);

  function fetchDishOption() {
    if (!restaurant?.id) {
      return message.error("Keine Restaurant ID gefunden");
    }

    setLoading(true);
    DishOptionAPI.queryList({
      filter: { restaurantID: { eq: restaurant?.id } },
    })
      .then(setListData)
      .finally(() => setTimeout(() => setLoading(false), 500));
  }

  const onUpdateMulti = async (newVal: boolean, optionID: DishOption["id"]) => {
    await DishOptionAPI.updateDishOptionByID({
      id: optionID,
      multipleSelection: newVal,
    });
    fetchDishOption();
  };

  const onUpdateObligatory = async (
    newVal: boolean,
    optionID: DishOption["id"]
  ) => {
    const updatedObligatory = await DishOptionAPI.updateDishOptionByID({
      id: optionID,
      obligatory: newVal,
    });
    console.debug("updatedObligatory", { updatedObligatory, newVal });

    fetchDishOption();
  };

  const onClickSetOptionForDish = async (option: DishOption) => {
    if (!props?.dish?.id) {
      return message.error("Keine Gericht ID gefunden");
    }

    if (!props?.dish) {
      return message.error("Kein Gericht gefunden");
    }

    // Query DishOption
    const originalDishOption = await DishOptionAPI.fetchDishOptionByID({
      id: option.id,
    });

    let dishIDs = originalDishOption?.dishIDs || [];

    const checked = Boolean(option.dishIDs?.includes(props.dish.id));

    if (checked) {
      // remove dishID from dishOption.dishIDs
      dishIDs = dishIDs?.filter((dishID) => dishID !== props?.dish?.id) || [];
    } else {
      // add dishID from dishOption.dishIDs
      dishIDs = [...dishIDs, props.dish.id];
    }

    await DishOptionAPI.updateDishOptionByID({
      id: option.id,
      dishIDs: dishIDs,
    });

    fetchDishOption();
  };

  const openCreateDishOptionItemModal = (optionID: string) => {
    setCreateOptionItemModal(true);
    setDishOptionID(optionID);
  };

  const closeCreateDishOptionItemModal = (items?: DishOptionItem[]) => {
    setCreateOptionItemModal(false);
    setDishOptionID(null);
  };

  const onSuccessCreateDishOption = () => {
    setCreateOptionModal(false);

    setTimeout(() => {
      fetchDishOption();
    }, 500);
  };

  const onClickDelete = async (dishOptionToDelete: DishOption) => {
    await DishOptionAPI.deleteItem({
      id: dishOptionToDelete.id,
      _version: dishOptionToDelete._version,
    });
    fetchDishOption();
  };

  return (
    <>
      <CreateDishOptionModal
        createDishOpen={createOptionModal}
        dish={props.dish}
        loading={loading}
        dishOptionID={dishOptionID}
        setCreateDishOpen={setCreateOptionModal}
        onSuccessCreateDishOption={onSuccessCreateDishOption}
      />

      <CreateDishOptionItemModal
        open={createOptionItemModal}
        dish={props.dish}
        loading={loading}
        dishOptionID={dishOptionID}
        onSuccess={onSuccessCreateDishOption}
        close={closeCreateDishOptionItemModal}
      />

      <List
        loading={loading}
        header={
          <Space
            align="center"
            style={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography.Text>{"Verfügbare Optionen"}</Typography.Text>

            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Button
                loading={loading}
                disabled={!props.dish}
                onClick={() => setCreateOptionModal(true)}
              >
                Neu <FileAddFilled />
              </Button>

              <Button
                style={{ marginLeft: 4 }}
                loading={loading}
                disabled={loading}
                onClick={fetchDishOption}
              >
                <ReloadOutlined />
              </Button>
            </Box>
          </Space>
        }
        itemLayout="vertical"
        dataSource={listData}
        renderItem={(option, index) => {
          const dishID = props?.dish?.id!;
          const checked = Boolean(option.dishIDs?.includes(dishID));

          return (
            <Collapse key={index} bordered={false}>
              <Collapse.Panel
                header={
                  <Space
                    direction="horizontal"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography.Text strong>{option.title}</Typography.Text>

                    <Space>
                      <Checkbox
                        checked={checked}
                        defaultChecked={checked}
                        onChange={(e) => onClickSetOptionForDish(option)}
                      />

                      <EditTwoTone
                        style={{ fontSize: 20 }}
                        onClick={() => openCreateDishOptionItemModal(option.id)}
                      />

                      <Popconfirm
                        placement={"topLeft"}
                        title={
                          "Sind Sie sicher das Sie die Gerichte-Option löschen möchten?"
                        }
                        onConfirm={() => onClickDelete(option)}
                        okText={t("app.menu-item.pop-alert.yes")}
                        cancelText={t("app.menu-item.pop-alert.no")}
                      >
                        <DeleteTwoTone
                          twoToneColor={"red"}
                          style={{ fontSize: 20 }}
                        />
                      </Popconfirm>
                    </Space>
                  </Space>
                }
                key={index + 1}
                style={{ marginBottom: 4 }}
              >
                <DishOptionItemsList
                  dishOption={option}
                  dishOptionID={dishOptionID}
                />

                <Box style={{ flexDirection: "row" }}>
                  <FormGroup style={{ flexDirection: "row" }}>
                    <MUIButton
                      style={{ marginRight: 8 }}
                      type="button"
                      variant="outlined"
                      size="small"
                      startIcon={<Settings />}
                      onClick={() => openCreateDishOptionItemModal(option.id)}
                    >
                      Verwalten
                    </MUIButton>

                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={Boolean(option.multipleSelection)}
                          onChange={() =>
                            onUpdateMulti(!option.multipleSelection, option.id)
                          }
                        />
                      }
                      label="Multi"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={Boolean(option.obligatory)}
                          onChange={() =>
                            onUpdateObligatory(!option.obligatory, option.id)
                          }
                        />
                      }
                      label="Pflicht"
                    />
                  </FormGroup>
                </Box>
              </Collapse.Panel>
            </Collapse>
          );
        }}
      />
    </>
  );
}

function DishOptionItemsList({
  dishOption,
  dishOptionID,
}: {
  dishOption: DishOption;
  dishOptionID: string | null;
}) {
  const [items, setItems] = useState<DishOptionItem[]>([]);

  const fetchItems = async (id: string) => {
    const data = await DishOptionItemAPI.queryList({
      filter: { dishOptionID: { eq: id } },
    });

    setItems(data);
  };

  useEffect(() => {
    if (!dishOption.id) {
      return;
    }
    console.log("DishOptionItemsList", dishOption.id);
    fetchItems(dishOption.id);

    const updateSub = DishOptionItemAPI.updateSub({
      filter: { dishOptionID: { eq: dishOption.id } },
    }).subscribe({
      next: ({ provider, value }) => {
        console.log("[OBSERVE DISH OPTION ITEM]", { value });
        fetchItems(dishOption.id);
      },
      error: (error) => console.warn(error),
    });

    return () => updateSub.unsubscribe();
  }, [dishOption.id, dishOptionID]);

  return (
    <List
      dataSource={items}
      renderItem={(item) => {
        return (
          <List.Item.Meta
            key={item.id}
            title={item?.title}
            description={
              item?.description
                ? item?.description + " | " + item?.price?.toFixed(2) + " €"
                : item?.price?.toFixed(2) + " €"
            }
          />
        );
      }}
    ></List>
  );
}

import { useState } from "react";

import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import { Divider, Menu, message } from "antd";
import { MenuItemType } from "antd/lib/menu/interface";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BankOutlined from "@ant-design/icons/BankOutlined";
import HistoryOutlined from "@ant-design/icons/HistoryOutlined";
import MenuOutlined from "@ant-design/icons/MenuOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import TimeOutlined from "@ant-design/icons/FieldTimeOutlined";
import UnorderedListOutlined from "@ant-design/icons/UnorderedListOutlined";

import { Restaurant } from "../../API";
import { RestaurantAPI } from "../../api-client";
import { useRestaurantContext } from "../../context/restaurant-context";
import awsconfig from "../../aws-exports";
import LanguageSelector from "../language-selector";
import RestaurantInfoBox from "../restaurant-info-box";
import RestaurantStatusSwitch from "../restaurant-status-switch";

const envStart = awsconfig.aws_user_files_s3_bucket.lastIndexOf("-");
const env = awsconfig.aws_user_files_s3_bucket.slice(envStart + 1);

export default function SideMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const restaurantContext = useRestaurantContext();

  const [minDeliveryTime, setMinDeliveryTime] = useState<
    Restaurant["minDeliveryTime"]
  >(restaurantContext?.restaurant?.minDeliveryTime || 30);

  const [maxDeliveryTime, setMaxDeliveryTime] = useState<
    Restaurant["maxDeliveryTime"]
  >(restaurantContext?.restaurant?.maxDeliveryTime || 60);

  const mainMenuItems: MenuItemType[] = [
    {
      key: "/",
      label: t("app.side-menu.orders"),
      icon: <UnorderedListOutlined />,
    },
    {
      key: "menu",
      label: t("app.side-menu.menu"),
      icon: <MenuOutlined />,
    },
    {
      key: "order-history",
      label: t("app.side-menu.order-history"),
      icon: <HistoryOutlined />,
    },
    {
      key: "invoices",
      label: t("app.invoices"),
      icon: <BankOutlined />,
    },
    {
      key: "business-hours",
      label: t("app.side-menu.business-hours"),
      icon: <TimeOutlined />,
    },
  ];

  const menuItems: MenuItemType[] = [
    ...(restaurantContext.restaurant ? mainMenuItems : []),
    {
      key: "settings",
      label: t("app.side-menu.settings"),
      icon: <SettingOutlined />,
    },
    /*  {
      key: "signOut",
      label: t("app.side-menu.sign-out"),
      icon: <LogoutOutlined />,
      danger: true,
    }, */
  ];

  const onClickMenuItem = async (menuItem: MenuItemType) => {
    navigate(`${menuItem?.key}`);
  };

  const updateDeliveryTime = async () => {
    if (!restaurantContext.restaurant?.id) {
      return;
    }

    const updatedRestaurant = await RestaurantAPI.updateRestaurantByID({
      id: restaurantContext.restaurant?.id,
      minDeliveryTime:
        restaurantContext.restaurant.minDeliveryTime === minDeliveryTime
          ? restaurantContext.restaurant.minDeliveryTime
          : minDeliveryTime,
      maxDeliveryTime:
        restaurantContext.restaurant.maxDeliveryTime === maxDeliveryTime
          ? restaurantContext.restaurant.maxDeliveryTime
          : maxDeliveryTime,
    });

    restaurantContext.setRestaurant(updatedRestaurant);
    message.success("Lieferzeiten aktualisiert");
  };

  return (
    <>
      <Box margin={2}>
        <Typography>Aktuelle Lieferzeiten</Typography>
        <FormControl margin={"dense"} size="small">
          <Box>
            <Typography variant="caption">Min: </Typography>
            <TextField
              style={{ marginBottom: 8 }}
              // label="min"
              value={minDeliveryTime}
              defaultValue={restaurantContext.restaurant?.minDeliveryTime.toString()}
              placeholder={restaurantContext.restaurant?.minDeliveryTime.toString()}
              onChange={(e) => setMinDeliveryTime(parseInt(e.target.value))}
            />
          </Box>
          <Box>
            <Typography variant="caption">Max: </Typography>
            <TextField
              // label="max"
              value={maxDeliveryTime}
              defaultValue={restaurantContext.restaurant?.maxDeliveryTime.toString()}
              placeholder={restaurantContext.restaurant?.maxDeliveryTime.toString()}
              onChange={(e) => setMaxDeliveryTime(parseInt(e.target.value))}
            />
          </Box>
          <Button
            disabled={
              restaurantContext.restaurant?.minDeliveryTime ===
                minDeliveryTime &&
              restaurantContext.restaurant?.maxDeliveryTime === maxDeliveryTime
            }
            onClick={updateDeliveryTime}
          >
            Speichern
          </Button>
        </FormControl>
      </Box>

      <Divider />
      <RestaurantStatusSwitch />
      <Divider />

      <Menu
        items={menuItems}
        onClick={onClickMenuItem}
        theme="light"
        mode="inline"
        defaultSelectedKeys={["1"]}
      />
      {env !== "prod" && (
        <>
          <Divider />
          <Box color={"orange"}>
            <Typography textAlign={"center"} fontWeight={"bold"}>
              {env.toUpperCase()}
            </Typography>
          </Box>
        </>
      )}
      <RestaurantInfoBox />
      <LanguageSelector />
    </>
  );
}

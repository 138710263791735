import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";

import {
  RestaurantCategory,
  GetRestaurantCategoryQuery,
  GetRestaurantCategoryQueryVariables,
  OnUpdateRestaurantCategorySubscription,
  OnUpdateRestaurantCategorySubscriptionVariables,
  UpdateRestaurantCategoryInput,
  UpdateRestaurantCategoryMutation,
  ListRestaurantCategoriesQuery,
  ListRestaurantCategoriesQueryVariables,
  CreateRestaurantCategoryInput,
  CreateRestaurantCategoryMutation,
  DeleteRestaurantCategoryInput,
  DeleteRestaurantCategoryMutation,
} from "../API";

import {
  getRestaurantCategory,
  listRestaurantCategories,
} from "../graphql/queries";
import { onUpdateRestaurantCategory } from "../graphql/subscriptions";
import {
  createRestaurantCategory,
  deleteRestaurantCategory,
  updateRestaurantCategory,
} from "../graphql/mutations";
import removeTypename from "./helper/remove-typename";
import addBaseFilterToVariables from "./helper/filter-deleted";

export async function fetchRestaurantCategoryByID(
  variables: GetRestaurantCategoryQueryVariables
) {
  const payload = await API.graphql<GraphQLQuery<GetRestaurantCategoryQuery>>(
    graphqlOperation(getRestaurantCategory, variables ? variables : {})
  );

  return payload.data?.getRestaurantCategory as RestaurantCategory;
}
export async function fetchListByRestaurantID(
  variables: ListRestaurantCategoriesQueryVariables
) {
  const payload = await API.graphql<
    GraphQLQuery<ListRestaurantCategoriesQuery>
  >(
    graphqlOperation(
      listRestaurantCategories,
      variables ? addBaseFilterToVariables(variables) : {}
    )
  );

  return payload.data?.listRestaurantCategories?.items as RestaurantCategory[];
}

export async function createItem(input: CreateRestaurantCategoryInput) {
  const payload = await API.graphql<
    GraphQLQuery<CreateRestaurantCategoryMutation>
  >({
    query: createRestaurantCategory,
    variables: { input: removeTypename(input) },
  });
  return payload.data?.createRestaurantCategory as RestaurantCategory;
}

export async function deleteItem(input: DeleteRestaurantCategoryInput) {
  await API.graphql<GraphQLQuery<DeleteRestaurantCategoryMutation>>({
    query: deleteRestaurantCategory,
    variables: { input },
  });
}

export async function updateRestaurantCategoryByID(
  input: UpdateRestaurantCategoryInput
) {
  const origin = await fetchRestaurantCategoryByID({ id: input.id });
  const inputWithVersion = {
    ...input,
    _version: origin?._version,
  };

  const payload = await API.graphql<
    GraphQLQuery<UpdateRestaurantCategoryMutation>
  >({
    query: updateRestaurantCategory,
    variables: { input: removeTypename(inputWithVersion) },
  });
  return payload.data?.updateRestaurantCategory as RestaurantCategory;
}

// Subscribe to update of categories
export const observeRestaurantCategories = (
  variables: OnUpdateRestaurantCategorySubscriptionVariables
) =>
  API.graphql<GraphQLSubscription<OnUpdateRestaurantCategorySubscription>>({
    query: onUpdateRestaurantCategory,
    variables: addBaseFilterToVariables(variables),
  });

import {
  Form,
  Input,
  InputNumber,
  Button,
  Space,
  Typography,
  FormInstance,
} from "antd";

export type IDishOptionItemForm = {
  id?: string;
  title: string;
  description: string | null | undefined;
  price: number;
};

type Props = {
  dishOptionItemsFormRef: FormInstance<IDishOptionItemForm>;
  disabled: boolean;
  updateDishOptionItemsList: (listItem: IDishOptionItemForm) => void;
};

export default function DishOptionItemForm(props: Props) {
  const onFinish = (values: IDishOptionItemForm) => {
    const result = {
      title: values.title,
      description: values.description,
      price: values.price,
    };

    props.updateDishOptionItemsList(result);
    props.dishOptionItemsFormRef.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Typography.Text strong>
        2 Zusatzoption hinzufügen (Ofen-Kartoffel, Extra-Käse, Brioch, Scharfe
        Soße etc.)
      </Typography.Text>

      <Form
        form={props.dishOptionItemsFormRef}
        disabled={props.disabled}
        layout={"vertical"}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={"Titel"}
          name={"title"}
          rules={[{ required: true }]}
          required
        >
          <Input placeholder={"Title"} />
        </Form.Item>
        <Form.Item
          label={"Allergene"}
          name={"description"}
          rules={[{ required: false }]}
          required={false}
        >
          <Input placeholder={"Description"} />
        </Form.Item>
        <Form.Item
          label={"Price"}
          name={"price"}
          rules={[{ required: true }]}
          required
        >
          <InputNumber />
        </Form.Item>

        <Form.Item>
          <Button disabled={props.disabled} type="primary" htmlType={"submit"}>
            Option hinzufügen
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}

import { useEffect, useState } from "react";

import { Storage, Logger } from "aws-amplify";
import { Image, ImageProps } from "antd";

const logger = new Logger("Storage.S3Image");

export default function S3Image({
  imgKey,
  ...restProps
}: { imgKey: string } & ImageProps) {
  const [image, setImage] = useState<string>();

  useEffect(() => {
    Storage.get(imgKey, {
      level: "public",
      track: true,
    })
      .then((url) => setImage(url))
      .catch((err) => logger.debug(err));
  }, [imgKey]);

  return <Image alt="S3Image" src={image} {...restProps} />;
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faPerson,
  faTruckFast,
} from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";

import { DeliveryMethod, DeliveryProvider, Order, Restaurant } from "../../API";

const DeliveryIcon = ({
  order,
  restaurant,
}: {
  order: Order;
  restaurant?: Restaurant | null;
}) => {
  // Determine delivery method and provider
  const isTastyDelivery =
    (order.deliveryMethod === DeliveryMethod.DELIVERY ||
      order.deliveryMethod === undefined) &&
    (order?.deliveryProvider === DeliveryProvider.TASTY ||
      order?.deliveryProvider === undefined);

  const isRestaurantDelivery =
    order.deliveryMethod === DeliveryMethod.DELIVERY &&
    order?.deliveryProvider === DeliveryProvider.RESTAURANT;

  const isPickup = order.deliveryMethod === DeliveryMethod.PICKUP;

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {isTastyDelivery && (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faTruckFast}
            size="1x"
            style={{ marginRight: 4 }}
          />
          <Typography>Lieferung durch Tasty</Typography>
        </div>
      )}
      {isRestaurantDelivery && (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faTruck}
            size="1x"
            style={{ marginRight: 4 }}
          />
          <Typography>
            Lieferung durch {restaurant?.name || "Restaurant"}
          </Typography>
        </div>
      )}
      {isPickup && (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faPerson}
            size="1x"
            style={{ marginRight: 4 }}
          />
          <Typography>Abholung durch Kunde</Typography>
        </div>
      )}
    </div>
  );
};

export default DeliveryIcon;

export default function addBaseFilterToVariables<
  T extends { filter?: T["filter"] }
>(variables: T): T {
  return {
    ...variables,
    filter: {
      ...variables.filter,
      _deleted: { ne: true },
    },
    limit: 30000,
  };
}

import { useEffect, useState } from "react";
import { Hub } from "aws-amplify";

function useAppLoader() {
  const [initialClearDone, setInitialClearDone] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    Hub.listen("auth", async (data) => {
      console.warn("useAppLoader | event: ", data.payload.event);
      if (data.payload.event === "signOut") {
      }
      if (data.payload.event === "signIn") {
        init();
      }
    });
  }, []);

  useEffect(() => {
    init();
  }, [setInitialClearDone]);

  async function init() {
    setInitialClearDone(false);
    setReady(false);
    setTimeout(() => {
      setInitialClearDone(true);
      setReady(true);
    }, 1000);
  }

  const appInitialized = initialClearDone; //  && ready && !clearingDataStore;

  console.info("useAppLoader | ", {
    appInitialized,
    initialClearDone,
    ready,
  });

  return { appInitialized, initialClearDone, ready };
}

export default useAppLoader;

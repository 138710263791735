import React from "react";
import {
  Button,
  Checkbox,
  Collapse,
  List,
  message,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import {
  DeleteTwoTone,
  FileAddFilled,
  ReloadOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import { t } from "i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { Dish, DishCategory } from "../../../API";
import { DishAPI, DishCategoryAPI } from "../../../api-client";
import { ImagePickData } from "../../settings";
import { useRestaurantContext } from "../../../context/restaurant-context";
import DishCategoryCreateForm from "../../../ui-components/DishCategoryCreateForm";
import DishCategoryUpdateForm from "../../../ui-components/DishCategoryUpdateForm";
import Picker from "../../../Picker";
import S3Image from "../../../S3Image";
import uploadImage from "../../../utils/uploadImage";

type Props = {
  dish?: Dish;
  setDish: (d: Dish) => void;
  restaurantID?: string;
};

export default function DishOptionsList(props: Props) {
  const { restaurant } = useRestaurantContext();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [categoryId, setCategoryId] = React.useState<string | null>(null);
  const [dishCategoryId, setDishCategoryId] = React.useState<string>();
  const [listData, setListData] = React.useState<DishCategory[]>([]);

  const [dragItemIndex, setDragItemIndex] = React.useState<number>();
  const [dragOverItemIndex, setDragOverItemIndex] = React.useState<number>();

  const [createCategoryModal, setCreateCategoryModal] =
    React.useState<boolean>(false);

  /**
   * Observe Model Order of type UPDATE & matching to order id
   */
  React.useEffect(() => {
    if (!props.dish?.id) {
      return;
    }
    fetchDishCategories();
  }, [props.dish]);

  const handleDragStart = (index: number) => {
    setDragItemIndex(index);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = async () => {
    const _listData = [...listData];

    const dragItem = _listData.splice(dragItemIndex || 0, 1);

    console.log({ dragItem, dragItemIndex, dragOverItemIndex });

    _listData.splice(dragOverItemIndex || 0, 0, dragItem[0]);
    setListData(_listData);

    for await (const item of _listData) {
      const index = _listData.indexOf(item);
      await DishCategoryAPI.updateDishCategoryByID({
        id: item.id,
        order: index,
      });
    }
  };

  const handleDragEnter = (index: number) => {
    setDragOverItemIndex(index);
  };
  const handleDragLeave = (event: any) => {
    // setDragOverItemIndex(undefined);
  };

  const handleDragEnd = (event: any) => {
    setDragItemIndex(undefined);
    setDragOverItemIndex(undefined);
  };

  const handleOpen = (id?: DishCategory["id"]) => {
    setCreateCategoryModal(true);
    setCategoryId(id || null);
  };

  const handleClose = () => {
    setCreateCategoryModal(false);

    setTimeout(() => {
      fetchDishCategories();
      setCategoryId(null);
    }, 500);
  };

  async function fetchDishCategories() {
    setLoading(true);

    setDishCategoryId(props.dish?.category?.id);

    DishCategoryAPI.queryList({
      filter: { restaurantID: { eq: restaurant?.id } },
    })
      .then((data) => {
        setListData(data.sort((a, b) => (a.order || 0) - (b.order || 0)));
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  }

  const onClickDelete = async (dishCategory: DishCategory) => {
    await DishCategoryAPI.deleteItem({
      id: dishCategory.id,
      _version: dishCategory._version,
    });
    fetchDishCategories();
  };

  const onClickSetCategory = async (
    checked: boolean,
    dishCategory: DishCategory
  ) => {
    if (!props?.dish?.id) {
      return message.error("Keine Gericht ID gefunden");
    }

    const updatedDish = await DishAPI.updateDishByID({
      id: props.dish.id,
      dishCategoryId: checked ? dishCategory.id : undefined,
    });

    props.setDish(updatedDish);
  };

  const onUpdateCategoryImage = async (
    category: DishCategory,
    data: ImagePickData
  ) => {
    console.log("onPick", data);

    const name =
      restaurant?.id +
      "/categories/" +
      category.name?.trim().toUpperCase() +
      "_" +
      data.name;

    if (!data) {
      return message.error("Leider konnte die Kategory nicht geladen werden");
    }

    const imageKey = await uploadImage(name, data.file, category.imageKey, 300);

    await DishCategoryAPI.updateDishCategoryByID({
      id: category.id,
      imageKey: imageKey,
    });

    const updatedList = await DishCategoryAPI.queryList({
      filter: { restaurantID: { eq: restaurant?.id } },
    });

    setListData(updatedList);
  };

  return (
    <>
      <CreateDishCategoryModal
        categoryId={categoryId}
        restaurantID={restaurant?.id}
        open={createCategoryModal}
        handleClose={handleClose}
      />

      <List
        loading={loading}
        header={
          <Space
            align="center"
            style={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography.Text>{"Verfügbare Kategorien"}</Typography.Text>

            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Button
                loading={loading}
                disabled={!props.dish}
                onClick={() => handleOpen()}
              >
                Neu <FileAddFilled />
              </Button>

              <Button
                style={{ marginLeft: 4 }}
                loading={loading}
                disabled={loading}
                onClick={fetchDishCategories}
              >
                <ReloadOutlined />
              </Button>
            </Box>
          </Space>
        }
        itemLayout="vertical"
        dataSource={listData}
        renderItem={(category, index) => {
          return (
            <>
              <div
                key={index}
                //className={dragOverItemIndex === index}
                style={{
                  padding: 0,
                  opacity: dragOverItemIndex === index ? 0.2 : 1,
                }}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop()}
                onDragEnter={() => handleDragEnter(index)}
                onDragLeave={handleDragLeave}
                onDragEnd={handleDragEnd}
              >
                <Collapse bordered={false}>
                  <Collapse.Panel
                    // showArrow={false}
                    header={
                      <Space
                        direction="horizontal"
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography.Text strong>
                          {category.name}
                        </Typography.Text>

                        <Space>
                          <Checkbox
                            checked={dishCategoryId === category.id}
                            onChange={(e) =>
                              onClickSetCategory(e.target.checked, category)
                            }
                          />

                          <EditTwoTone
                            style={{ fontSize: 20 }}
                            onClick={() => handleOpen(category.id)}
                          />
                          <Popconfirm
                            placement={"topLeft"}
                            title={
                              "Sind Sie sicher das sie die Kategorie entfernen möchten?"
                            }
                            onConfirm={() => onClickDelete(category)}
                            okText={t("app.menu-item.pop-alert.yes")}
                            cancelText={t("app.menu-item.pop-alert.no")}
                          >
                            <DeleteTwoTone
                              twoToneColor={"red"}
                              style={{ fontSize: 20 }}
                            />
                          </Popconfirm>
                        </Space>
                      </Space>
                    }
                    key={index + 1}
                    style={{ marginBottom: 4 }}
                  >
                    <List.Item
                      actions={[
                        <Picker
                          title={"Bild hochladen"}
                          accept="image/*"
                          theme={null}
                          // onPick={this.handlePick}
                          onPick={(data) =>
                            onUpdateCategoryImage(category, data)
                          }
                        />,
                      ]}
                    >
                      <List.Item.Meta
                        // title={category.name}
                        description={category?.description}
                      />

                      {category.imageKey && (
                        <S3Image imgKey={category.imageKey} height={100} />
                      )}
                    </List.Item>
                  </Collapse.Panel>
                </Collapse>
              </div>
            </>
          );
        }}
      />
    </>
  );
}

type CreateDishCategoryModalProps = {
  open: boolean;
  handleClose: () => void;
  categoryId: string | null;
  restaurantID?: string;
};

const createDishOptionStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CreateDishCategoryModal(props: CreateDishCategoryModalProps) {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={createDishOptionStyle}>
        {!props.categoryId ? (
          <DishCategoryCreateForm
            onSuccess={props.handleClose}
            onSubmit={(data) => {
              // We need to call onSubmit callback
              // if we dont do this restaurantID will always ne undefined
              return {
                name: data.name,
                description: data.description,
                restaurantID: props.restaurantID,
              };
            }}
            overrides={{
              restaurantID: {
                value: props.restaurantID,
              },
            }}
          />
        ) : (
          <DishCategoryUpdateForm
            id={props.categoryId}
            onSuccess={props.handleClose}
          />
        )}
      </Box>
    </Modal>
  );
}

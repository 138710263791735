import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import "moment/locale/de";

import App from "./App";

// import "antd/dist/antd.css";
import "./index.css";

import "./assets/fonts/Amble-Regular.ttf";
import "./assets/fonts/Amble-Bold.ttf";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

// import i18n (needs to be bundled ;))
import "./i18n";
import ErrorFallback from "./ErrorFallback";

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>
  </BrowserRouter>
);
